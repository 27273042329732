import React, { useRef } from "react";
import PropTypes from "prop-types";

const VideoEmbed = ({ videoUrl }) => {
  const videoRef = useRef(null);

  return (
    <div
      style={{
        borderRadius: "20px",
        border: "10px solid #32A7AB",
        overflow: "hidden",
      }}
    >
      <video
        ref={videoRef}
        src={videoUrl}
        controls
        style={{
          width: "100%",
          height: "auto",
          margin: "0",
          marginBottom: "-10px",
        }}
      />
    </div>
  );
};

VideoEmbed.propTypes = {
  videoUrl: PropTypes.string.isRequired,
};

export default VideoEmbed;
