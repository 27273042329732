import React from "react";
import Button from "react-bootstrap/Button";
import Image from "../Components/Image";
import house1 from "../Images/investinThailand.png";
import thailandPic from "../Images/thailand.jpg";
import dcombe from "../Images/David.jpeg";
import djordy from "../Images/Dave.png";
import kl from "../Images/Kl.png";
import lCombe from "../Images/laeti.jpeg";
import wMin from "../Images/wMin.png";
import kTon from "../Images/kTon.jpg";
import stang from "../Images/stang.jpg";
import lamri from "../Images/lamri.png";
import choodam from "../Images/choodam.jpg";
import johnny from "../Images/johnny.jpg";
import { Row, Col } from "react-bootstrap";
import { CiTwitter, CiFacebook, CiInstagram, CiLinkedin } from "react-icons/ci";
import { Link } from "react-router-dom";
import Partners from "../Components/Partners";
import YoutubeEmbed from "../Components/YoutubeEmbed";

export const About = () => {
  const images = [
    {
      src: dcombe,
      alt: "David Combe",
      name: "David Combe",
      role: "",
    },
    {
      src: lCombe,
      alt: "Laetitia Combe",
      name: "Laetitia Combe",
      role: "",
    },
    {
      src: djordy,
      alt: "Davide Jordy",
      name: "Davide Jordy",
      role: "",
    },
    {
      src: wMin,
      alt: "Wain Min",
      name: "Wain Min",
      role: "",
    },
    {
      src: choodam,
      alt: "Jessada",
      name: "Jessada",
      role: "",
    },
    {
      src: stang,
      alt: "Stang",
      name: "Stang",
      role: "",
    },
  ];

  return (
    <div>
      <div className="container mt-5">
        <div className="row justify-content-center align-items-start">
          {" "}
          {/* First Column - Text */}
          <div className="col-md-6 custom-top-margin">
            {" "}
            <h2 style={{ margin: 30 }}>A propos</h2>{" "}
            <p style={{ margin: 50, fontSize: 15 }}>
              {" "}
              <span className="investInThaland">InvestInThaland</span> ouvre les
              portes d'un marché immobilier autrefois réservé aux professionnels
              de l'investissement. Notre équipe d'experts immobiliers examine
              scrupuleusement les dossiers chaque semaine pour ne retenir que
              les opportunités les plus avantageuses.
              <br />
              <br />
              Vous pouvez investir à partir de 15 000€ et commencer à percevoir
              un rendement locatif mensuel. Notre objectif est de rendre les
              solutions d'investissement accessibles au plus grand nombre.{" "}
              <br />
              <br />
              Nos projets d'<b>Achat-revente et d'Achat locatif</b> vous
              permettent de combiner deux stratégies d'investissement pour
              développer votre patrimoine immobilier.
            </p>
            <p style={{ margin: 50, fontSize: 20 }}>
              <Link to="/signup">
                <Button variant="outline-primary">
                  Investir dès maintenant
                </Button>
              </Link>
              <Link to="/project">
                <Button variant="primary" style={{ marginLeft: "10px" }}>
                  Découvrir les projets
                </Button>
              </Link>
            </p>
          </div>
          {/* Second Column - Image (wrapped in Card) */}
          <div className="col-md-6 row justify-content-center align-items-start">
            <div style={{ width: "100%", maxWidth: "500px" }}>
              <Image imagePath={house1} />
            </div>
          </div>
        </div>
      </div>
      <div className="container mt-5">
        <div className="row justify-content-center align-items-start">
          {" "}
          <div className="col-md-6 row justify-content-center align-items-start">
            <div style={{ width: "100%", maxWidth: "500px" }}>
              <Image imagePath={thailandPic} />
            </div>
          </div>
          <div className="col-md-6 custom-top-margin">
            <p style={{ margin: 50, fontSize: 15, marginTop: "100px" }}>
              {" "}
              La Thaïlande offre des opportunités d'investissement extrêmement
              rentables pour les résidents et les étrangers. Son marché
              immobilier attractif avec un rendement locatif pouvant atteindre
              20%, ainsi que son économie stable et diversifiée, attirent de
              nombreux investisseurs et entreprises internationales.
              <br />
              <br />
              Les villes à privilégier pour investir sont Bangkok, Pattaya,
              Chiang Mai, Phuket et Ko Samui, en raison de leur attrait
              touristique. L'investissement locatif est recommandé en raison de
              la forte demande pour la location saisonnière dans ces zones.
              <br />
              <br />
              La Thaïlande offre de nombreux avantages, notamment un coût de la
              vie plus bas que celui en France, un climat ensoleillé et des
              prestations médicales de qualité. De plus, l'économie thaïlandaise
              en plein essor offre des rendements intéressants et une fiscalité
              avantageuse, sans taxe foncière, frais de succession ou impôt sur
              le revenu élevé. Une convention fiscale entre la France et la
              Thaïlande évite la double imposition.
            </p>
            <p style={{ margin: 50, fontSize: 20 }}>
              <Link to="/signup">
                <Button variant="outline-primary">
                  Investir dès maintenant
                </Button>
              </Link>
              <Link to="/project">
                <Button variant="primary" style={{ marginLeft: "10px" }}>
                  Découvrir les projets
                </Button>
              </Link>
            </p>
          </div>
        </div>
      </div>
      <br />
      <br />
      <br />
      <div className="container mt-5">
        <h2 className="text-center mb-4">Qui sommes nous ?</h2>
        <Row className="justify-content-center">
          {images.slice(0, 30).map((image, index) => (
            <Col key={index} xs={6} sm={6} md={4} lg={3} className="mb-4">
              <div
                className="bg-primary rounded shadow-sm py-5 px-4 d-flex flex-column align-items-center"
                style={{ maxWidth: "200px" }}
              >
                <img
                  src={image.src}
                  alt={image.alt}
                  width="100"
                  height="100"
                  className="img-fluid rounded-circle mb-3 img-thumbnail shadow-sm"
                />
                <h5 className="mb-0 text-white">{image.name}</h5>
                <span className="small text-uppercase text-white">
                  {image.role}
                </span>
                <ul className="social mb-0 list-inline mt-3">
                  <li className="list-inline-item">
                    <a href="#" className="social-link text-white">
                      <CiFacebook />
                    </a>
                  </li>
                  <li className="list-inline-item">
                    <a href="#" className="social-link text-white">
                      <CiTwitter />
                    </a>
                  </li>
                  <li className="list-inline-item">
                    <a href="#" className="social-link text-white">
                      <CiInstagram />
                    </a>
                  </li>
                  <li className="list-inline-item">
                    <a href="#" className="social-link text-white">
                      <CiLinkedin />
                    </a>
                  </li>
                </ul>
              </div>
            </Col>
          ))}
        </Row>
      </div>

      <div className="container mt-5">
        <h2 className="text-center mb-4">Nos locaux à koh Samui</h2>
        <Row className="justify-content-center">
          <div style={{ width: "50%", height: "auto" }}>
            <YoutubeEmbed
              embedId={process.env.PUBLIC_URL + "/LocauxInvestInThailand.mp4"}
            />
          </div>
        </Row>
      </div>

      <div className="container mt-5">
        <div className="row justify-content-center align-items-start">
          <div className="alert alert-warning" role="alert">
            En résumé, <span className="investInThaland">InvestInThaland</span>{" "}
            permet à chacun d'accéder facilement à l'immobilier thaïlandais
            prometteur, avec des possibilités d'investissement diversifiées et
            des avantages fiscaux attractifs.
          </div>
        </div>
      </div>
      <div className="container text-center">
        <Partners />
      </div>
    </div>
  );
};
