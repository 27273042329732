import React from "react";
import datatUse from "../Images/dataUse.jpg";
import Image from "../Components/Image";

export const DataUsePolicy = () => {
  return (
    <div>
      <div className="container mt-5">
        <div className="row justify-content-center align-items-start">
          {" "}
          {/* First Column - Text */}
          <div className="col-md-6 custom-top-margin">
            <h2 style={{ margin: 30 }}>Politique d’utilisation des données</h2>
            <h4 className="investInThalandColor">
              Types d'informations collectées
            </h4>
            <p>
              Pour assurer le bon fonctionnement d'
              <span className="investInThaland">Invest In Thailand</span> et de
              ses produits et services, nous traitons des informations
              concernant nos utilisateurs. Les types d'informations que nous
              collectons dépendent de la manière dont ils utilisent nos
              services.
            </p>
            <p>
              Pour en savoir plus sur les informations collectées et leur
              suppression, veuillez consulter les paramètres d'
              <span className="investInThaland">Invest In Thailand</span>.
            </p>
            <h5>1.1. Informations fournies par les utilisateurs</h5>
            <p>
              Nous collectons le contenu, les communications et d'autres
              informations que les utilisateurs fournissent lorsqu'ils utilisent
              nos services, notamment lorsqu'ils créent un compte.
            </p>
            <p>
              Données bénéficiant de protections spéciales Les utilisateurs
              peuvent choisir de fournir des informations spéciales dans leur
              profil Invest in Thailand, telles que l'origine raciale ou
              ethnique, les croyances philosophiques ou l'affiliation à un
              syndicat professionnel, qui sont soumises à des protections
              spéciales conformément à la législation européenne.
            </p>
            <p>
              {" "}
              Utilisation des services Nous collectons des informations sur la
              manière dont les utilisateurs utilisent nos services, telles que
              les fonctionnalités utilisées, les actions effectuées, l'heure, la
              fréquence et la durée de leurs activités, ainsi que l'utilisation
              de nos fonctionnalités.
            </p>
          </div>
          {/* Second Column - Image (wrapped in Card) */}
          <div className="col-md-6 row justify-content-center align-items-start">
            <div style={{ width: "100%", maxWidth: "500px" }}>
              <Image imagePath={datatUse} />
            </div>
          </div>
        </div>
        <div className="row justify-content-center align-items-start">
          {" "}
          {/* First Column - Text */}
          <div className="col-md-12 custom-top-margin"></div>
          <p>
            Informations sur les transactions Si les utilisateurs effectuent des
            achats ou des transactions financières sur nos services, nous
            collectons des informations sur ces transactions, y compris les
            données de paiement, les informations de compte, les données de
            facturation et de livraison, ainsi que leurs coordonnées
          </p>
          <h5>1.2. Informations sur les appareils</h5>
          <p>
            Nous collectons des informations concernant les appareils connectés
            au web utilisés par nos utilisateurs, tels que les ordinateurs, les
            téléphones et les téléviseurs connectés. Ces informations
            comprennent les attributs des appareils, les activités sur
            l'appareil, les signaux des appareils, les données des paramètres de
            l'appareil, les informations réseau et de connexion, ainsi que les
            données des cookies.
          </p>
          <h5>1.3. Informations fournies par les partenaires </h5>
          <p>
            Les annonceurs, les développeurs d'applications et les éditeurs
            peuvent nous envoyer des informations concernant les activités des
            utilisateurs en dehors d'
            <span className="investInThaland">Invest In Thailand</span>.
          </p>
          <h4 className="investInThalandColor">
            Utilisation de ces informations
          </h4>
          <p>
            Nous utilisons les informations collectées pour fournir,
            personnaliser et améliorer nos services, ainsi que pour assurer la
            sécurité et la sûreté des utilisateurs.
          </p>
          <h5>2.1. Personnalisation des services</h5>
          <p>
            Nous utilisons les informations pour personnaliser les
            fonctionnalités des services{" "}
            <span className="investInThaland">Invest In Thailand</span> et
            fournir une expérience cohérente sur l'ensemble de nos produits.
          </p>
          <h5>2.2. Sûreté, intégrité et sécurité</h5>
          <p>
            Nous utilisons les informations pour vérifier les comptes et les
            activités, détecter et prévenir les comportements dangereux ou
            indésirables, et protéger l'intégrité de nos services.
          </p>
          <h5>2.3. Communication avec les utilisateurs</h5>
          <p>
            Nous utilisons les informations pour communiquer avec les
            utilisateurs, leur fournir des mises à jour sur nos services et
            répondre à leurs demandes.
          </p>
          <h4 className="investInThalandColor">Partage de ces informations</h4>
          <p>
            Nous partageons les informations collectées avec certains
            partenaires dans le cadre de nos activités, mais nous ne vendons
            jamais les données des utilisateurs à quiconque.
          </p>
          <h5>Collaboration au sein du groupe d'Invest in Thailand</h5>
          Nous collaborons avec les entités du groupe Invest in thailand pour
          offrir une expérience cohérente à nos utilisateurs et optimiser nos
          services.
          <h5>Fondement juridique</h5>
          <p>
            Nous collectons, utilisons et partageons les données des
            utilisateurs dans le respect des lois applicables et de nos intérêts
            légitimes.
          </p>
          <h5>Exercice des droits prévus par la RGPD</h5>
          <p>
            Les utilisateurs ont le droit de consulter, de rectifier, de
            transférer et de supprimer leurs données, ainsi que de s'opposer et
            de restreindre le traitement de celles-ci.
          </p>
          <h5>Conservation des données</h5>
          <p>
            Nous conservons les données des utilisateurs aussi longtemps que
            nécessaire pour fournir nos services et respecter les obligations
            légales.
          </p>
          <h5>Réponse aux demandes légales</h5>
          <p>
            Nous pouvons accéder, conserver et partager les informations des
            utilisateurs en réponse à des demandes légales ou pour prévenir des
            activités illégales.
          </p>
          <h5>Transfert international des données</h5>
          <p>
            Nous pouvons transférer des informations des utilisateurs à
            l'international pour fournir nos services dans le monde entier.
          </p>
          <h5>Mise à jour de la politique de confidentialité</h5>
          <p>
            En cas de modifications importantes de la politique, nous en
            informerons les utilisateurs et leur donnerons accès à la version
            révisée avant de continuer à utiliser nos services.
          </p>
          <h5>Questions concernant la confidentialité</h5>
          <p>
            Pour toute question relative à la politique de confidentialité, les
            utilisateurs peuvent nous contacter via nos coordonnées.
          </p>
        </div>
      </div>
    </div>
  );
};
