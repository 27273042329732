import React, { useState, useEffect } from "react";
import ApiService from "../Services/ApiService";
import ImageCardHold from "../Components/ImageCardHoldFlip";
import IProject from "../Interfaces/IProject";

interface ProgressData {
  projectId: number;
  progress: {
    userId: number;
    projectId: number;
    numberOfShares: number;
  }[];
}

export const Project = () => {
  const [projects, setProjects] = useState<IProject[]>([]);
  const [progressData, setProgressData] = useState<ProgressData | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      const apiService = new ApiService<IProject[]>(
        process.env.REACT_APP_CF_API_URL + "/project"
      );

      try {
        const response = await apiService.get();
        if (response.ok) {
          const data = await response.json();
          setProjects(data);
        } else {
          console.error("Request failed:", response.status);
        }
      } catch (error: any) {
        console.error("An error occurred:", error.message);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchProgressData = async () => {
      if (projects.length === 0) return;

      const miscProjects = projects.filter(
        (project) => project.type === "Misc"
      );
      const progressDataPromises = miscProjects.map(async (project) => {
        const progressApiService = new ApiService<number>(
          `${process.env.REACT_APP_CF_API_URL}/projectSubscription/${project.id}`
        );

        try {
          const response = await progressApiService.get();
          if (response.ok) {
            const data = await response.json();
            const newProgressData: ProgressData = {
              projectId: project.id,
              progress: data,
            };
            setProgressData(newProgressData);
          } else {
            console.error("Request for progress data failed:", response.status);
          }
        } catch (error: any) {
          console.error(
            "An error occurred while fetching progress data:",
            error.message
          );
        }
      });

      Promise.all(progressDataPromises).catch((error) => {
        console.error("An error occurred while fetching progress data:", error);
      });
    };

    fetchProgressData();
  }, [projects]);

  const rows: JSX.Element[][] = [];
  let numberOfProjectsMap = new Map();

  if (
    progressData &&
    "projectId" in progressData &&
    Array.isArray(progressData.progress)
  ) {
    progressData.progress.forEach((item) => {
      if (numberOfProjectsMap.has(item.projectId)) {
        numberOfProjectsMap.set(
          item.projectId,
          numberOfProjectsMap.get(item.projectId) + 1
        );
      } else {
        numberOfProjectsMap.set(item.projectId, 1);
      }
    });
  }

  const sortedProjects = [...projects].sort((a, b) => {
    const isAHoldAndFullySubscribed =
      a.type === "Hold" && a.numberOfShares === a.totalNumberOfShares;
    const isBHoldAndFullySubscribed =
      b.type === "Hold" && b.numberOfShares === b.totalNumberOfShares;

    if (isAHoldAndFullySubscribed && !isBHoldAndFullySubscribed) {
      return 1;
    } else if (!isAHoldAndFullySubscribed && isBHoldAndFullySubscribed) {
      return -1;
    } else {
      return 0;
    }
  });

  for (let i = 0; i < sortedProjects.length; i += 3) {
    const row = sortedProjects.slice(i, i + 3);
    rows.push(
      row.map((project) => {
        const isHoldAndFullySubscribed =
          project.type === "Hold" &&
          project.numberOfShares === project.totalNumberOfShares;

        const link = isHoldAndFullySubscribed
          ? "/#/project"
          : `/#/projectDetails/${project.id}`;

        return (
          <div key={project.id} className="col-md-6 custom-top-margin">
            <a
              href={link}
              style={{ textDecoration: "none", position: "relative" }}
            >
              <div style={{ position: "relative" }}>
                {isHoldAndFullySubscribed && (
                  <div
                    style={{
                      position: "absolute",
                      top: 0,
                      left: 0,
                      width: "60%",
                      height: "100%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      zIndex: 2,
                      transform: "rotate(-45deg)",
                    }}
                  >
                    <span
                      style={{
                        fontSize: "6rem",
                        fontWeight: "bold",
                        color: "#38AAAE",
                      }}
                    >
                      Financé
                    </span>
                  </div>
                )}
                <ImageCardHold
                  imagePath={`${
                    process.env.REACT_APP_CF_API_URL
                  }/files/Projects/${encodeURIComponent(
                    project.type
                  )}/${encodeURIComponent(
                    project.directoryName
                  )}/${encodeURIComponent(project.frontCoverImage)}`}
                  width={60}
                  flipHold={project.type}
                  name={project.title}
                  price={`${project.price?.toLocaleString("fr-FR")} € - ${
                    project.price
                      ? (project.price * 39.39).toLocaleString("fr-FR") + " ฿"
                      : ""
                  }`}
                  progressBar={
                    project.type === "Misc"
                      ? project.numberOfShares -
                        (numberOfProjectsMap.get(project.id) || 0)
                      : (project.totalNumberOfShares * 100) /
                        project.numberOfShares
                  }
                  valueP={
                    project.type === "Misc"
                      ? Math.ceil(
                          (100 * (numberOfProjectsMap.get(project.id) || 0)) /
                            project.numberOfShares
                        )
                      : 0
                  }
                  subTitle={project.subTitle}
                />
                <br />
              </div>
              <div
                style={{
                  position: "absolute",
                  bottom: "0",
                  left: "0",
                  transform: "translate(-50%, 50%) rotate(-45deg)",
                  background: "rgba(219, 10, 91, 0.6)",
                  color: "white",
                  padding: "5px 10px",
                  borderRadius: "0 0 20px 0",
                }}
              >
                {project.type === "Flip" && "Achat revente"}
                {project.type === "Hold" && "Achat locatif"}
                {project.type === "Misc" && "Achat"}
              </div>
            </a>
          </div>
        );
      })
    );
  }

  return (
    <div>
      <div className="container mt-5">
        {rows.map((row, rowIndex) => (
          <div
            key={rowIndex}
            className="row justify-content-start align-items-start"
          >
            {row}
          </div>
        ))}
      </div>
      <br />
    </div>
  );
};
