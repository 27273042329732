import React, { useState, useEffect } from "react";
import countriesData from "../LocalData/Countries.json";
import { Dropdown } from "react-bootstrap";

interface CountryInfo {
  name: string;
  image: string;
  phone: string[];
}

const CountryDropDownPhone = ({ onSelectCountry, defaultValue }: any) => {
  const [selectedPhone, setSelectedPhone] = useState<string>(defaultValue);

  const handleCountrySelect = (countryCode: string) => {
    const selectedCountryInfo =
      countriesData[countryCode as keyof typeof countriesData];

    // Update the selected phone when a new country is selected
    setSelectedPhone(selectedCountryInfo.phone[0]);

    onSelectCountry(selectedCountryInfo.phone[0]);
  };

  const countryKeys = Object.keys(countriesData);

  return (
    <>
      <Dropdown className="mr-2">
        <Dropdown.Toggle id="dropdownCountry" className="btn-sm">
          {/* Display the selected phone dynamically */}
          {selectedPhone && (
            <>
              {Object.entries(countriesData).map(
                ([countryCode, countryInfo]) => {
                  if (countryInfo.phone.includes(selectedPhone)) {
                    return (
                      <React.Fragment key={countryCode}>
                        <img
                          src={countryInfo.image}
                          alt={countryCode}
                          width="40"
                          height="30"
                          className="mr-2"
                        />
                        {selectedPhone}
                      </React.Fragment>
                    );
                  }
                  return null;
                }
              )}
            </>
          )}
        </Dropdown.Toggle>

        <Dropdown.Menu style={{ maxHeight: "200px", overflowY: "auto" }}>
          <Dropdown.Item key={"FR"} onClick={() => handleCountrySelect("FR")}>
            <img
              src={countriesData["FR"].image}
              alt={countriesData["FR"].phone[0]}
              width="40"
              height="30"
              className="mr-2"
            />
            {countriesData["FR"].phone[0]} {countriesData["FR"].name}
          </Dropdown.Item>
          <div className="dropdown-divider"></div>
          {countryKeys.map((countryCode) => (
            <Dropdown.Item
              key={countryCode}
              onClick={() => handleCountrySelect(countryCode)}
            >
              <img
                src={
                  countriesData[countryCode as keyof typeof countriesData].image
                }
                alt={
                  countriesData[countryCode as keyof typeof countriesData]
                    .phone[0]
                }
                width="40"
                height="30"
                className="mr-2"
              />
              {
                countriesData[countryCode as keyof typeof countriesData]
                  .phone[0]
              }{" "}
              {countriesData[countryCode as keyof typeof countriesData].name}
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>
    </>
  );
};

export default CountryDropDownPhone;
