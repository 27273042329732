import { BsArrowRightCircle } from "react-icons/bs";
import { BsFillPinMapFill, BsPeople, BsFiletypePdf } from "react-icons/bs";
import { GiModernCity, GiCityCar, GiCommercialAirplane } from "react-icons/gi";
import { GrMoney } from "react-icons/gr";
import { FcMoneyTransfer } from "react-icons/fc";

export const iconMappings = {
  "!!": BsArrowRightCircle,
  "__": BsFillPinMapFill,
  "::": GiModernCity,
  "@@": GiCityCar,
  "&&": GiCommercialAirplane,
  "##": BsPeople,
  "%%": GrMoney
};