import React from "react";
import Button from "react-bootstrap/Button";
import Image from "../Components/Image";
import sea from "../Images/thesea.jpg";
import analytics from "../Images/analytics.jpg";
import ThailandC from "../Components/Thailand";
import {
  BsGraphUpArrow,
  BsAirplaneFill,
  BsFill1CircleFill,
  BsFill4CircleFill,
} from "react-icons/bs";
import { Link } from "react-router-dom";

export const Thailand = () => {
  return (
    <div>
      <div className="container mt-5">
        <div className="row justify-content-center align-items-start">
          {" "}
          {/* First Column - Text */}
          <div className="col-md-6 custom-top-margin">
            {" "}
            <h2 style={{ margin: 30 }}>La Thailande</h2>{" "}
            <p style={{ margin: 50, fontSize: 15 }}>
              La Thaïlande est devenue un marché immobilier très attrayant,
              attirant chaque année plus de 35 millions de touristes. Avec son
              économie dynamique, sa situation géographique centrale en Asie du
              Sud-Est, ses paysages paradisiaques et une fiscalité avantageuse,
              le pays offre de belles opportunités d'investissement.
              <br />
              <br /> Reconnu pour son hospitalité et sa qualité de vie, la
              Thaïlande propose un coût de la vie bien inférieur à celui de la
              France (2 à 3 fois moins élevé). En plus d'infrastructures
              modernes de qualité, le pays bénéficie d'une classe moyenne en
              pleine expansion et de paysages variés, ce qui en fait une
              destination très prisée.
            </p>
            <p style={{ margin: 50, fontSize: 20 }}>
              <Link to="/signup">
                <Button variant="outline-primary">
                  Investir dès maintenant
                </Button>
              </Link>
              <Link to="/project">
                <Button variant="primary" style={{ marginLeft: "10px" }}>
                  Découvrir les projets
                </Button>
              </Link>
            </p>
          </div>
          {/* Second Column - Image (wrapped in Card) */}
          <div className="col-md-6 row justify-content-center align-items-start">
            <div style={{ width: "100%", maxWidth: "500px" }}>
              <Image imagePath={sea} />
            </div>
          </div>
        </div>
      </div>
      <div className="container mt-5">
        <div className="row justify-content-center align-items-start">
          <div className="col-md-6 row justify-content-center align-items-start">
            <h2>Quelques chiffres</h2>{" "}
            <div style={{ width: "100%", maxWidth: "500px" }}>
              <Image imagePath={analytics} />
            </div>
          </div>
          <div className="col-md-6 custom-top-margin">
            <p
              style={{
                margin: 50,
                fontSize: 15,
                marginTop: "20%",
                textDecoration: "none",
              }}
            >
              <ul style={{ listStyle: "none" }}>
                <li>
                  <BsGraphUpArrow
                    style={{ fontSize: "24px", margin: "20px" }}
                  />
                  2ème puissance économique d'Asie du Sud-Est
                </li>
                <li>
                  <BsAirplaneFill
                    style={{ fontSize: "24px", margin: "20px" }}
                  />
                  30 millions de touristes chaque année
                </li>
                <li>
                  <BsFill1CircleFill
                    style={{ fontSize: "24px", margin: "20px" }}
                  />
                  1ère destination d'Asie du Sud-Est
                </li>
                <li>
                  <BsFill4CircleFill
                    style={{ fontSize: "24px", margin: "20px" }}
                  />
                  4% taux de croissance sur les 5 dernières années
                </li>
                <li>
                  <BsFill1CircleFill
                    style={{ fontSize: "24px", margin: "20px" }}
                  />
                  Bangkok ville la plus visitée au monde
                </li>
              </ul>
            </p>
            <p style={{ margin: 50, fontSize: 20 }}>
              <Link to="/signup">
                <Button variant="outline-primary">
                  Investir dès maintenant
                </Button>
              </Link>
              <Link to="/project">
                <Button variant="primary" style={{ marginLeft: "10px" }}>
                  Découvrir les projets
                </Button>
              </Link>
            </p>
          </div>
        </div>
      </div>
      <br />
      <br />
      <div className="container text-center">
        <ThailandC />
      </div>
    </div>
  );
};
