import React from "react";
import Carousel from "react-bootstrap/Carousel";
import Image from "../Components/Image";

function CarouselImages({ url, imageNames }) {
  if (!imageNames) {
    return <div></div>;
  }

  const imageArray = imageNames.split(", ");

  return (
    <Carousel fade>
      {imageArray.map((imageName, index) => (
        <Carousel.Item key={index}>
          <Image imagePath={`${url}${imageName}`} />
        </Carousel.Item>
      ))}
    </Carousel>
  );
}

export default CarouselImages;
