import React from "react";
import Button from "react-bootstrap/Button";
import ImageCardHold from "../Components/ImageCardHoldFlip";
import hold from "../Projects/Hold/SeaHorse/9.jpg";
import HoldProcess from "../Components/HoldProcess";
import { Link } from "react-router-dom";

export const Hold = () => {
  return (
    <div>
      <div className="container mt-5">
        <div className="row justify-content-center align-items-start">
          <div className="col-md-6 custom-top-margin">
            <h2 style={{ margin: 30 }}>Achat locatif</h2>
            <p style={{ margin: 50, fontSize: 15 }}></p>
            <h4>
              Générez des revenus passifs grâce à nos opportunités de location
              en Thailande.
            </h4>
            <br />
            <p>
              Découvrez une opportunité unique avec Invest In Thailand : devenez
              propriétaire d'un bien immobilier en Thailande pour seulement 15
              000 € !
            </p>
            <p>
              L'investissement dans l'immobilier locatif a toujours été un moyen
              privilégié pour créer, protéger et développer le patrimoine des
              particuliers.
            </p>
            <p>
              Notre offre vous permet de saisir cette chance exceptionnelle et
              de profiter d'un retour sur investissement attractif. Si une
              occasion se présente, les propriétés sont revendues avec profit,
              vous permettant ainsi de vous lancer dans le locatif à haut
              rendement en toute confiance.
            </p>
            <p>
              Ne manquez pas cette occasion de bâtir votre avenir financier avec
              Invest In Thailand. Commencez dès maintenant votre parcours vers
              une meilleure sécurité financière et la réalisation de vos rêves
              immobiliers au pays du sourire !
            </p>
            <p style={{ margin: 50, fontSize: 20 }}>
              <Link to="/signup">
                <Button variant="outline-primary">
                  Investir dès maintenant
                </Button>
              </Link>
              <Link to="/project">
                <Button variant="primary" style={{ marginLeft: "10px" }}>
                  Découvrir les projets
                </Button>
              </Link>
            </p>
          </div>
          {/* Second Column - Image  */}
          <div className="col-md-6 row justify-content-center align-items-start">
            <ImageCardHold
              imagePath={hold}
              width={60}
              flipHold="Hold"
              name={"New seahorse residence koh samui"}
              price={"750 000 € - 29 056 207 ฿"}
              progressBar={6}
            />
          </div>
        </div>
      </div>
      <div className="container mt-5">
        <div className="row justify-content-center align-items-start"></div>
        <HoldProcess />
      </div>
    </div>
  );
};
