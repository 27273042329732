import React from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import { BsSun, BsBuildingCheck } from "react-icons/bs";
import { BiMoney, BiChevronRight } from "react-icons/bi";

const Thailand = () => {
  return (
    <div>
      <Container>
        <Row className="justify-content-center">
          {/* Première colonne */}
          <Col md={6} className="mb-4">
            <Card style={{ borderRadius: "20px" }}>
              <Card.Header
                style={{ backgroundColor: "#38AAAE", color: "white" }}
              >
                <BsSun size={22} /> &nbsp; Une qualité de vie exceptionelle
              </Card.Header>
              <Card.Body>
                <Card.Title>Une qualité de vie exceptionnelle</Card.Title>
                <Card.Text>
                  <ul style={{ listStyle: "none" }}>
                    <li>Météo exceptionnelle toute l'année</li>
                    <li>Paysages à couper le souffle</li>
                    <li>Coût de la vie avantageux</li>
                    <li>Population cosmopolite et accueillante</li>
                    <li>Transports abordables</li>
                    <li>Cuisine délicieuse et variée</li>
                  </ul>
                </Card.Text>
              </Card.Body>
              <h6>
                7ème position du classement des 20 meilleurs pays où il fait bon
                vivre
              </h6>
            </Card>
          </Col>

          {/* Deuxième colonne */}
          <Col md={6} className="mb-4">
            <Card style={{ borderRadius: "20px" }}>
              <Card.Header
                style={{ backgroundColor: "#38AAAE", color: "white" }}
              >
                <BsBuildingCheck size={22} /> &nbsp; Des Infrastructures de
                Qualité
              </Card.Header>
              <Card.Body>
                <Card.Title>La Thaïlande, un pays modernisé</Card.Title>
                <Card.Text>
                  <ul style={{ listStyle: "none" }}>
                    <li>Modernisation rapide</li>
                    <li>Infrastructures développées</li>
                    <li>Transports à grande vitesse</li>
                    <li>Excellents hôpitaux et tarifs compétitifs</li>
                    <li>Destination leader du tourisme médical</li>
                    <li>&nbsp;</li>
                  </ul>
                </Card.Text>
              </Card.Body>
              <h6>Un Très Bon Rapport Qualité / Prix de l’Immobilier</h6>
            </Card>
          </Col>
        </Row>
      </Container>
      <Container>
        <Row className="justify-content-center">
          {/* Première colonne */}
          <Col md={6} className="mb-4">
            <Card style={{ borderRadius: "20px" }}>
              <Card.Header
                style={{ backgroundColor: "#38AAAE", color: "white" }}
              >
                <BiMoney size={22} /> &nbsp; Une Fiscalité Très Attractive
              </Card.Header>
              <Card.Body>
                <Card.Title>Fiscalité immobilière très avantageuse</Card.Title>
                <Card.Text>
                  <ul style={{ listStyle: "none" }}>
                    <li>Pas de taxes d’habitation</li>
                    <li>Pas de taxes foncières</li>
                    <li>
                      Taxe de 15% sur les gains financier pour un investissement
                      locatif en Thailande
                    </li>
                    <li>Pas d’ISF</li>
                    <li>
                      Pas de droit de succession (pour les biens en dessous de
                      100 millions de bahts)
                    </li>
                    <li>Faible imposition sur la plus-value à la revente</li>
                    <li>
                      Fiscalité thaïlandaise appliquée lors de l'achat
                      (convention de non double imposition)
                    </li>
                  </ul>
                </Card.Text>
              </Card.Body>
              <h6>Fiscalité immobilière extrêmement favorable.</h6>
            </Card>
          </Col>

          {/* Deuxième colonne */}
          <Col md={6} className="mb-4">
            <Card style={{ borderRadius: "20px" }}>
              <Card.Header
                style={{ backgroundColor: "#38AAAE", color: "white" }}
              >
                <BiChevronRight size={22} /> &nbsp; Excellent rapport
                qualité/prix de l'immobilier.
              </Card.Header>
              <Card.Body>
                <Card.Title>Essor du marché immobilier thaïlandais</Card.Title>
                <Card.Text>
                  <ul style={{ listStyle: "none" }}>
                    <li>
                      Demande croissante sur le marché immobilier thaïlandais
                    </li>
                    <li>Large choix d'appartements, condominiums et villas</li>
                    <li>
                      Prix attractifs en fonction de l'emplacement et du type de
                      bien
                    </li>
                    <li>Normes de construction et de design élevées</li>
                    <li>Biens disponibles à la vente et à la location</li>
                    <li>&nbsp;</li>
                    <li>&nbsp;</li>
                    <li>&nbsp;</li>
                  </ul>
                </Card.Text>
              </Card.Body>
              <h6>
                Le Marché Immobilier en Thaïlande : Une Offre en Pleine
                Expansion
              </h6>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Thailand;
