import React, { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import Image from "../Components/Image";
import { Button } from "react-bootstrap";
import ApiService from "../Services/ApiService";
import email from "../Images/emailValidator.jpg";
import { useNavigate } from "react-router-dom";

export const ValidationEmail = () => {
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertStyle, setAlertStyle] = useState("");
  const navigate = useNavigate();
  const [queryParameters] = useSearchParams();
  const token = queryParameters.get("p");
  const [validateEmail, setValidateEmail] = useState(false);

  const handleNavigate = () => {
    navigate("/signin");
  };

  useEffect(() => {
    const fetchData = async () => {
      const apiService = new ApiService<any>(
        `${process.env.REACT_APP_CF_API_URL}/user/validate?token=${token}`
      );

      try {
        const response = await apiService.get();
        if (response.ok) {
          const data = await response.json();
          setValidateEmail(data);
          if (data) {
            setAlertMessage("Email et compte validés.");
            setAlertStyle("success");
            setShowAlert(true);
          }
        } else {
          setAlertMessage("Compte inconnu.");
          setAlertStyle("danger");
          setShowAlert(true);
        }
      } catch (error: any) {
        console.error("An error occurred:", error.message);
      }
    };

    fetchData();
  }, []);

  return (
    <div>
      <div className="container mt-5">
        <div className="row justify-content-center align-items-start">
          {/* First Column - Text */}
          <div className="col-md-6 custom-top-margin">
            <h2 style={{ margin: 30 }}>Validation de l'adresse email</h2>
            <p>Nous vérifion la validité de votre adresse email...</p>
            {showAlert && (
              <>
                <br />
                <br />
                <div
                  className={`alert alert-${alertStyle} text-center`}
                  role="alert"
                >
                  {alertMessage}
                </div>
              </>
            )}
            {validateEmail != null && validateEmail ? (
              <>
                <br />
                <br />
                <p style={{ textAlign: "center" }}>
                  <Button variant="primary" onClick={handleNavigate}>
                    Se connecter
                  </Button>
                </p>
              </>
            ) : (
              <></>
            )}
          </div>
          <div className="col-md-6 row justify-content-center align-items-start">
            <div style={{ width: "100%", maxWidth: "500px" }}>
              <Image imagePath={email} />
            </div>
          </div>
        </div>
      </div>
      <div className="container mt-5">
        <div className="row justify-content-center align-items-start"></div>
        <div className="col-md-6 row justify-content-center align-items-start"></div>
      </div>
    </div>
  );
};
