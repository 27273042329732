import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import ApiService from "../Services/ApiService";
import Alert from "react-bootstrap/Alert";

interface PromoCodeProps {
  onPromoCodeChange: (code: string) => void;
}

const PromoCode: React.FC<PromoCodeProps> = ({ onPromoCodeChange }) => {
  const [promoCode, setPromoCode] = useState("");
  const [appliedPromoCode, setAppliedPromoCode] = useState("");

  const handlePromoCodeChange = (event: any) => {
    const code = event.target.value;
    setPromoCode(code);
    // Call the callback function to update promo code in the parent
    onPromoCodeChange(code);
  };

  const handleApplyPromoCode = async () => {
    const apiService = new ApiService<any>(
      `${process.env.REACT_APP_CF_API_URL}/PromoCode/verify/${promoCode}`
    );

    try {
      const response = await apiService.get();

      if (response.ok) {
        const responseData = await response.json();
        if (responseData) {
          setAppliedPromoCode("true");
        } else {
          setAppliedPromoCode("false");
        }
      } else {
        setAppliedPromoCode("false"); // Set to "false" as a string for an invalid promo code
      }
    } catch (error) {
      setAppliedPromoCode("false"); // Set to "false" as a string for an invalid promo code
    }
  };

  return (
    <div>
      <label htmlFor="promoCode">Promo Code </label>{" "}
      <input
        type="text"
        id="promoCode"
        value={promoCode}
        onChange={handlePromoCodeChange}
      />{" "}
      <Button onClick={handleApplyPromoCode} variant="primary">
        Vérification du code.
      </Button>
      {appliedPromoCode !== null && (
        <div>
          <br />
          {appliedPromoCode === "false" ? (
            <Alert variant="danger">Le code promo n'est pas valide</Alert>
          ) : appliedPromoCode === "true" ? (
            <Alert variant="success">Code promo valide</Alert>
          ) : (
            <p></p>
          )}
        </div>
      )}
    </div>
  );
};

export default PromoCode;
