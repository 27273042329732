import React from "react";
import YoutubeEmbed from "../Components/YoutubeEmbed";

const Testimonials = () => {
  return (
    <div className="container mt-5">
      <div className="row justify-content-center align-items-start">
        <h2 style={{ margin: 30 }}>Témoignages</h2>
        <div className="col-md-2 custom-top-margin"></div>
      </div>
      <div className="row justify-content-center align-items-start">
        {" "}
        <div className="col-md-2 custom-top-margin">
          <p>
            <YoutubeEmbed embedId="https://video.wixstatic.com/video/7aa0d8_fb1eb1ca7443444d928320163882eb8e/1080p/mp4/file.mp4" />
          </p>
        </div>
        <div className="col-md-2 custom-top-margin">
          <p>
            <YoutubeEmbed embedId="https://video.wixstatic.com/video/7aa0d8_0019c868bee84baabd630e9557dc26e1/480p/mp4/file.mp4" />
          </p>
        </div>
        <div className="col-md-2 custom-top-margin">
          <p>
            <YoutubeEmbed embedId="https://video.wixstatic.com/video/7aa0d8_b65141e361084a79bccbe794d4176d87/1080p/mp4/file.mp4" />
          </p>
        </div>
        <div className="col-md-2 custom-top-margin">
          <p>
            <YoutubeEmbed embedId="https://video.wixstatic.com/video/7aa0d8_d946b15894e145ae845b32f4f7aba1ef/1080p/mp4/file.mp4" />
          </p>
        </div>
        <div className="col-md-2 custom-top-margin">
          <p>
            <YoutubeEmbed embedId="https://video.wixstatic.com/video/7aa0d8_ad270623fa8747f1a10419a156055ccd/1080p/mp4/file.mp4" />
          </p>
        </div>
      </div>
    </div>
  );
};

export default Testimonials;
