import React, { useState, useEffect } from "react";
import AccountMenu from "../Components/AccountNavigation";
import { useUser } from "../Contexts/UserContext";
import ApiService from "../Services/ApiService";
import IProjectsUser from "../Interfaces/IProjectsUser";

export const Subscription = () => {
  const [projects, setProjects] = useState<IProjectsUser[]>([]);
  const { user, setUser } = useUser();

  useEffect(() => {
    const fetchData = async () => {
      const apiService = new ApiService<IProjectsUser[]>(
        process.env.REACT_APP_CF_API_URL + "/project/projects/" + user?.id,
        user.token
      );

      try {
        const response = await apiService.get();
        if (response.ok) {
          const data = await response.json();
          setProjects(data);
        } else {
          console.error("Request failed:", response.status);
        }
      } catch (error: any) {
        console.error("An error occurred:", error.message);
      }
    };

    fetchData();
  }, []);

  return (
    <div className="container">
      <div className="row">
        {/* Colonne pour le menu */}
        <div className="col-md-2">
          <h1>&nbsp;</h1>
          <AccountMenu />
        </div>
        <div className="col-md-10">
          <h1>Mes souscriptions</h1>
          <table className="table table-hover">
            <thead>
              <tr>
                <th scope="col">Type</th>
                <th scope="col">Projets</th>
                <th scope="col">Part(s) ou Num. de lot</th>
                <th scope="col">Montant</th>
              </tr>
            </thead>
            <tbody>
              {projects.map((project, index) => (
                <tr key={index}>
                  <td>{project.type}</td>
                  <td>{project.projectName}</td>
                  <td>
                    {project.numberOfShares} (
                    {project.shareAmount.toLocaleString("fr-FR")} €)
                  </td>
                  <td>
                    {project.type === "Misc"
                      ? project.shareAmount.toLocaleString("fr-FR") + " €"
                      : (
                          project.numberOfShares * project.shareAmount
                        ).toLocaleString("fr-FR") + " €"}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};
