import React from "react";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

const CustomCircularProgressbar = ({
  value = 0,
  flipHold = "",
  valueMisc = 0,
}) => {
  const customTextStyle = {
    fontSize: "16px",
    fontWeight: "bold",
    fill: "#f88",
    whiteSpace: "pre-wrap", // Ajoutez cette propriété pour gérer les retours à la ligne
    textAlign: "center",
  };

  let percentage = flipHold === "Misc" ? "" : "%";
  const valueP = flipHold === "Misc" ? valueMisc : value;

  return (
    <div style={{ width: "75px" }}>
      <CircularProgressbar
        value={valueP}
        text={`${value}${percentage}`}
        strokeWidth={10}
        styles={buildStyles({
          pathColor: "rgba(62, 152, 199, 0.75)",
          textColor: "#3AABAF",
          trailColor: "#d6d6d6",
          backgroundColor: "#3e98c7",
          textSize: "15px",
        })}
        renderText={(text) => <text style={customTextStyle}>{text}</text>}
      />
    </div>
  );
};

export default CustomCircularProgressbar;
