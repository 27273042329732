import React, { useState, useEffect } from "react";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import { Button } from "react-bootstrap";

interface IPaypal {
  description: string;
  amount: number;
  onSuccess: () => void;
}

const Paypal: React.FC<IPaypal> = ({ description, amount, onSuccess }) => {
  const [show, setShow] = useState(false);

  const createOrder = (data: any, actions: any) => {
    return actions.order
      .create({
        purchase_units: [
          {
            description,
            amount: {
              value: amount,
              currency_code: "EUR",
            },
          },
        ],
      })
      .then((orderID: any) => {
        return orderID;
      });
  };

  const onApprove = (data: any, actions: any) => {
    return actions.order.capture().then(function (details: any) {
      const { payer } = details;
      onSuccess();
    });
  };

  const onError = (err: any) => {
    console.error(err);
    alert("An error occurred with your payment");
  };

  return (
    <PayPalScriptProvider
      options={{
        clientId: `${process.env.REACT_APP_PAYPAL_CLIENTID}`,
        currency: "EUR",
      }}
    >
      <div>
        <div className="product-text">
          <h1>{description}</h1>
        </div>

        <div className="product-price-btn" style={{ textAlign: "center" }}>
          {/* <p>{`€${amount}`}</p> */}
          <br />
          <Button variant="success" type="submit" onClick={() => setShow(true)}>
            Réserver
          </Button>
        </div>
        <br />

        {show ? (
          <PayPalButtons
            style={{ layout: "vertical" }}
            createOrder={createOrder}
            onApprove={onApprove}
            onError={onError}
          />
        ) : null}
      </div>
    </PayPalScriptProvider>
  );
};

export default Paypal;
