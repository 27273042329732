import React, { useState, useEffect } from "react";
import { useUser } from "../Contexts/UserContext";
import AccountMenu from "../Components/AccountNavigation";
import { Button } from "react-bootstrap";
import ApiService from "../Services/ApiService";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
//https://episyche.com/blog/how-to-integrate-paypal-with-react-application
export const Wallet = () => {
  const [show, setShow] = useState(false);
  const [success, setSuccess] = useState(false);
  const [ErrorMessage, setErrorMessage] = useState("");
  const [orderID, setOrderID] = useState(false);

  // creates a paypal order
  const createOrder = (data: any, actions: any) => {
    return actions.order
      .create({
        purchase_units: [
          {
            description: "Sunflower",
            amount: {
              value: 20,
            },
          },
        ],
      })
      .then((orderID: any) => {
        setOrderID(orderID);
        return orderID;
      });
  };

  // check Approval
  const onApprove = (data: any, actions: any) => {
    return actions.order.capture().then(function (details: any) {
      const { payer } = details;
      setSuccess(true);
    });
  };

  //capture likely error
  const onError = (data: any, actions: any) => {
    setErrorMessage("An Error occured with your payment ");
  };

  useEffect(() => {
    if (success) {
      alert("Payment successful!!");
      console.log("Order successful . Your order id is--", orderID);
    }
  }, [success]);

  return (
    <div className="container">
      <div className="row">
        <div className="col-md-2">
          <h1>&nbsp;</h1>
          <AccountMenu />
        </div>
        <div className="col-md-10">
          <h1>Mon portefeuille</h1>
          <PayPalScriptProvider
            options={{
              clientId: `${process.env.REACT_APP_PAYPAL_CLIENTID}`,
              currency: "EUR",
            }}
          >
            <div>
              <div className="wrapper">
                <div className="product-img">
                  <img
                    src="https://cdn.pixabay.com/photo/2021/08/15/06/54/sunflower-6546993_1280.jpg"
                    alt="SunFlower"
                    height="320"
                    width="300"
                  />
                </div>
                <div className="product-info">
                  <div className="product-text">
                    <h1>Sunflower</h1>
                  </div>

                  <div className="product-price-btn">
                    <p>€20</p>
                    <br></br>
                    <Button
                      variant="primary"
                      type="submit"
                      onClick={() => setShow(true)}
                    >
                      Buy now
                    </Button>
                  </div>
                </div>
              </div>
              <br></br>
              {show ? (
                <PayPalButtons
                  style={{ layout: "vertical" }}
                  createOrder={createOrder}
                  onApprove={onApprove}
                />
              ) : null}
            </div>
          </PayPalScriptProvider>
        </div>
      </div>
    </div>
  );
};
