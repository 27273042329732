import React, { useRef } from "react";
import PropTypes from "prop-types";

const YoutubeEmbed = ({ embedId }) => {
  const iframeRef = useRef(null);

  const handlePlayVideo = () => {
    iframeRef.current.contentWindow.postMessage(
      '{"event":"command","func":"playVideo","args":""}',
      "*"
    );
  };

  return (
    <div
      className="video-wrapper"
      style={{ borderRadius: "20px", border: "10px solid #32A7AB" }}
    >
      <iframe
        ref={iframeRef}
        src={`${embedId}?autoplay=0`}
        frameBorder="0"
        allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        title="Embedded youtube"
      />
      <button onClick={handlePlayVideo}>Lecture</button>
    </div>
  );
};

YoutubeEmbed.propTypes = {
  embedId: PropTypes.string.isRequired,
};

export default YoutubeEmbed;
