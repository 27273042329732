import React from "react";
import Button from "react-bootstrap/Button";
import HowItWorks from "../Components/HowItWorks";
import ImageCard from "../Components/ImageCard";
import Offers from "../Components/Offers";
import Testimonials from "../Components/Testimonials";
import Partners from "../Components/Partners";
import { Link } from "react-router-dom";

export const Home = () => {
  return (
    <div>
      <div className="container mt-5 background-video">
        <div
          style={{
            position: "relative",
            paddingBottom: "56.25%",
            width: "100%",
            height: 0,
          }}
        >
          <video
            autoPlay
            loop
            muted
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "auto",
              objectFit: "cover",
            }}
          >
            <source
              src="https://video.wixstatic.com/video/7aa0d8_d5d047812c2e4fb895cf331ff26b8d0c/1080p/mp4/file.mp4"
              type="video/mp4"
            />
            {/* Ajoutez d'autres sources pour les différents formats de vidéo */}
          </video>
        </div>
        <div
          style={{
            color: "white",
            textAlign: "center",
            position: "absolute",
            top: "12vh",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <div style={{ fontSize: "3.5vw", whiteSpace: "nowrap" }}>
            <b>INVESTISSEZ EN THAILANDE</b>
          </div>
          <h1 style={{ fontSize: "2.5vw" }}>
            Investissements immobilier à haut rendement
          </h1>
          <Link to="/signup">
            <Button variant="danger">
              Inscrivez-vous pour les alertes projets à venir.
            </Button>
          </Link>
        </div>
      </div>
      <div className="container mt-5 "></div>
      <div className="container text-center" style={{ marginTop: "-20vh" }}>
        <div className="row justify-content-center align-items-start">
          {" "}
          {/* Change align-items-center to align-items-start */}
          {/* First Column - Text */}
          <div className="col-md-6 custom-top-margin">
            {" "}
            {/* Remove top margin to move the content higher */}
            <h2 style={{ margin: 30 }}>
              Générer un revenu complémentaire tous les mois
            </h2>{" "}
            {/* Remove margin here */}
            <p style={{ margin: 50, fontSize: 15 }}>
              {" "}
              {/* Remove margin here */}
              Explorez des opportunités d'investissement exceptionnelles à haut
              rendement, avec un montant d'investissement à partir de 15 000 €
            </p>
            <p style={{ margin: 50, fontSize: 20 }}>
              <Link to="/signup">
                <Button variant="outline-primary" className="mb-2">
                  Investir dès maintenant
                </Button>
              </Link>
              <Link to="/project">
                <Button
                  variant="primary"
                  style={{ marginLeft: "10px" }}
                  className="mb-2"
                >
                  Découvrir les projets
                </Button>
              </Link>
            </p>
          </div>
          {/* Second Column - Image (wrapped in Card) */}
          <div className="col-md-6">
            <div style={{ width: "100%", maxWidth: "500px" }}>
              <a href="/#/projectDetails/5" style={{ textDecoration: "none" }}>
                <ImageCard
                  imagePath={`${process.env.REACT_APP_CF_API_URL}/files/Projects/Misc/JungleVillas/7.jpg`}
                  title={"Jungle villas - koh samui"}
                  subTitle="Opération Achat"
                  price={
                    "145 000 € - " +
                    (145000 * 37.84).toLocaleString("fr-FR") +
                    " ฿"
                  }
                />
              </a>
            </div>
          </div>
        </div>
      </div>

      <br />
      <br />

      <div className="container text-center">
        <h2 className="text-center" style={{ margin: 0 }}>
          Thailande :{" "}
        </h2>
        <h4>
          Boostez vos investissements avec{" "}
          <span style={{ color: "#32A7AB" }}>l'achat / revente</span> et
          <br />
          l'investissement locatif !
        </h4>{" "}
      </div>
      <br />
      <div className="container text-center">
        <p>
          <Link to="/signup">
            <Button variant="primary" style={{ marginLeft: "10px" }}>
              Démarrer
            </Button>
          </Link>
        </p>
      </div>
      <br />
      <div className="container text-center">
        <div
          className="text-center"
          style={{ margin: 0, marginBottom: "20px" }}
        >
          <HowItWorks />
        </div>
      </div>
      <div className="container text-center">
        <Offers />
        <Link to="/signup">
          <Button variant="danger">
            Inscrivez-vous pour les alertes projets à venir.
          </Button>
        </Link>
      </div>
      <Testimonials />
      <div className="container text-center">
        <Partners />
      </div>
      <br />
    </div>
  );
};
