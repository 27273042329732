import React, { useState } from "react";
import { Navbar, Nav, NavDropdown, Button } from "react-bootstrap";
import logo from "../Images/logo5.png";
import franceFlag from "../Images/FranceFlag.png";
import ukFlag from "../Images/UKFlag.png";
import { useUser } from "../Contexts/UserContext";
import { useNavigate } from "react-router-dom";

const Navigation = () => {
  const [selectedLanguage, setSelectedLanguage] = useState("fr");
  const { user, setUser } = useUser(); // Utilisez useUser pour accéder à l'état de l'utilisateur
  const navigate = useNavigate();

  const handleLanguageChange = (eventKey) => {
    setSelectedLanguage(eventKey);
  };

  const handleLogout = () => {
    // Réinitialisez l'utilisateur à null et d'autres variables si nécessaire
    setUser(null);
    // Autres réinitialisations

    // Redirigez l'utilisateur vers la page de connexion ou ailleurs
    navigate("/"); // Assurez-vous d'importer useNavigate et de l'utiliser ici
  };

  const handleAccountClick = () => {
    navigate("/account");
  };

  return (
    <div
      className="d-flex justify-content-center"
      style={{ backgroundColor: "#F8F9FA" }}
    >
      <Navbar bg="light" expand="lg">
        <Navbar.Brand href="/#/home">
          <img src={logo} alt="Invest in Thailand" />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="mr-auto">
            {/* Insérez ici le code pour les 4 items */}
            <Nav.Link href={`#/about?scroll=top&timestamp=${Date.now()}`}>
              Qui Sommes nous
            </Nav.Link>
            <Nav.Link href="#/thailand">Thailande</Nav.Link>
            <NavDropdown title="Fonctionnement" id="basic-nav-dropdown">
              <NavDropdown.Item href="#/hold">Achat locatif</NavDropdown.Item>
              <NavDropdown.Item href="#/flip">Achat revente</NavDropdown.Item>
            </NavDropdown>
            <Nav.Link href="/#/project">Projets</Nav.Link>
            <Nav.Link href={`#/faq?scroll=top&timestamp=${Date.now()}`}>
              FAQ
            </Nav.Link>
            {/* Insérez ici le code pour le menu déroulant */}
            <NavDropdown
              title={
                <img
                  src={selectedLanguage === "fr" ? franceFlag : ukFlag}
                  alt={selectedLanguage}
                  className="mr-1"
                />
              }
              id="basic-nav-dropdown"
              onSelect={handleLanguageChange}
            >
              {/* Utilisez l'attribut `eventKey` pour sélectionner par défaut le drapeau français */}
              <NavDropdown.Item href="#action/3.1" eventKey="fr">
                <img
                  src={franceFlag}
                  alt="French"
                  className="img-fluid mr-1" // Ajoutez la classe img-fluid ici
                />
              </NavDropdown.Item>
              <NavDropdown.Item href="#action/3.2" eventKey="en">
                <img
                  src={ukFlag}
                  alt="English"
                  className="img-fluid mr-1" // Ajoutez la classe img-fluid ici
                />
              </NavDropdown.Item>
            </NavDropdown>
          </Nav>
          <Nav>
            {user ? ( // Vérifiez si l'utilisateur est connecté
              // Affichez le bouton de déconnexion si l'utilisateur est connecté
              <>
                <Button variant="outline-success" onClick={handleAccountClick}>
                  {user.firstName}
                </Button>

                <Button
                  variant="outline-danger"
                  style={{ marginLeft: "10px", marginRight: "10px" }}
                  onClick={handleLogout}
                >
                  Déconnexion
                </Button>
              </>
            ) : (
              // Affichez les boutons de connexion sinon
              <>
                <Nav.Link href="#/signin">
                  <Button
                    variant="outline-primary"
                    style={{ marginRight: "10px" }}
                  >
                    Se connecter
                  </Button>
                </Nav.Link>
                <Nav.Link href="#/signup">
                  <Button variant="primary">Créer mon compte</Button>
                </Nav.Link>
              </>
            )}
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </div>
  );
};

export default Navigation;
