import React from "react";
import { BsFillHouseCheckFill } from "react-icons/bs";
import { FaUsers, FaMoneyBillWave } from "react-icons/fa";
import { BsBarChart } from "react-icons/bs";

const HowItWorks = () => (
  <div>
    <div className="row">
      <div className="col-md-6">
        <h3 style={{ display: "flex", alignItems: "center" }}>
          <BsFillHouseCheckFill style={{ marginRight: "10px" }} />
          Choix des projets
        </h3>
        <div
          style={{ fontSize: "15px", textAlign: "left", fontWeight: "normal" }}
        >
          <ul>
            <li>
              Notre équipe de spécialistes immobiliers explore activement
              plusieurs opportunités d'investissement immobilier.
            </li>
            <li>
              Nous analysons minutieusement chaque projet en respectant
              scrupuleusement notre cahier des charges, en prenant en compte des
              critères essentiels tels que l'emplacement stratégique et la
              vitalité du quartier.
            </li>
            <li>
              Grâce à cette approche rigoureuse, nous sélectionnons les
              meilleures opportunités d'investissement, offrant ainsi à nos
              investisseurs des placements immobiliers de qualité avec un fort
              potentiel de rendement.
            </li>
          </ul>

          <br />
        </div>
      </div>
      <div className="col-md-6">
        <h3 style={{ display: "flex", alignItems: "center" }}>
          <FaUsers style={{ marginRight: "10px" }} />
          Présentation des projets
        </h3>
        <div
          style={{ fontSize: "15px", textAlign: "left", fontWeight: "normal" }}
        >
          <ul>
            <li>
              Nous exposons les projets choisis sur notre plateforme et nous
              organisons une campagne de financement participatif auprès de la
              communauté.
            </li>
          </ul>
        </div>
      </div>
    </div>

    <div className="row">
      <div className="col-md-6">
        <h3 style={{ display: "flex", alignItems: "center" }}>
          <BsBarChart style={{ marginRight: "10px" }} />
          Gestion et reporting
        </h3>
        <div
          style={{ fontSize: "15px", textAlign: "left", fontWeight: "normal" }}
        >
          <ul>
            <li>
              Nous nous occupons de la gestion, de l'analyse et du suivi complet
              du projet.
            </li>
          </ul>

          <br />
        </div>
      </div>
      <div className="col-md-6">
        <h3 style={{ display: "flex", alignItems: "center" }}>
          <FaMoneyBillWave style={{ marginRight: "10px" }} />
          Revente
        </h3>
        <div
          style={{ fontSize: "15px", textAlign: "left", fontWeight: "normal" }}
        >
          <ul>
            <li>Nous nous occupons de la revente des biens.</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
);

export default HowItWorks;
