import React, { useState } from "react";

interface DropdownListProps {
  onChange: (selectedValue: number) => void;
  houseIdsTaken: any[];
  projectId: number;
}

const MiscDropdown: React.FC<DropdownListProps> = ({
  onChange,
  houseIdsTaken,
  projectId,
}) => {
  const [selectedValue, setSelectedValue] = useState<number | undefined>(
    undefined
  );

  const handleSelectChange = (newValue: number) => {
    setSelectedValue(newValue);
    onChange(newValue);
  };

  const dropdownItems = [];
  for (let i = 1; i <= 30; i++) {
    // Vérifie si i n'est pas présent dans houseIdsTaken.numberOfShares
    const isExcluded = houseIdsTaken.some((item) => item.numberOfShares === i);

    // Si i n'est pas dans houseIdsTaken.numberOfShares, l'ajouter à dropdownItems
    if (!isExcluded) {
      dropdownItems.push(
        <a
          key={i}
          className="dropdown-item"
          onClick={() => handleSelectChange(i)}
        >
          {i}
        </a>
      );
    }
  }

  return (
    <>
      {projectId === 8 ? (
        <b>Nombre de part(s) :</b>
      ) : (
        <b>Numéro de la maison souhaitée (cf PDF) :</b>
      )}{" "}
      <div className="btn-group">
        <button
          type="button"
          className="btn btn-primary dropdown-toggle"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
          data-bs-toggle="dropdown"
        >
          {selectedValue !== undefined ? selectedValue : 0}
        </button>
        <div
          className="dropdown-menu"
          style={{ maxHeight: "200px", overflowY: "auto" }}
        >
          {dropdownItems}
        </div>
      </div>
    </>
  );
};

export default MiscDropdown;
