import React from "react";
import risk from "../Images/risk.jpg";
import Image from "../Components/Image";

export const Risk = () => {
  return (
    <div>
      <div className="container mt-5">
        <div className="row justify-content-center align-items-start">
          {" "}
          {/* First Column - Text */}
          <div className="col-md-6 custom-top-margin">
            <h2 style={{ margin: 30 }}>Principaux Risques et Avertissements</h2>
            <h3>Divulgation et risques Avertissement.</h3>
            <h4 className="investInThalandColor">
              Régulation et Informations Obligatoires
            </h4>
            <p>
              <span className="investInThaland">Invest In Thailand</span> est
              soumise à une stricte régulation de la Thailand Financial Services
              Authority.
            </p>
            <p>
              IMPORTANT : Les investissements dans l'immobilier et les actions
              non cotées comportent des risques. Votre capital est susceptible
              d'être exposé à des pertes, et les rendements anticipés ne sont
              pas garantis. Si vous décidez d'utiliser un crédit ou un emprunt
              pour investir sur{" "}
              <span className="investInThaland">Invest In Thailand</span>,
              veuillez noter qu'il y a un risque accru, car même en cas de
              dépréciation de votre investissement ou de non-remboursement, vous
              serez toujours tenu responsable du remboursement.
            </p>
            <h4 className="investInThalandColor">
              Avertissements concernant les Risques d'Investissement
            </h4>
            <p>
              <span className="investInThaland">Invest In Thailand</span> ne
              prétend pas éliminer les risques inhérents à l'acquisition directe
              de propriétés résidentielles sans l'intermédiaire d'une
              hypothèque. La propriété partagée et le moment de sortie peuvent
              introduire des risques supplémentaires. Nous vous conseillons donc
              de diversifier vos investissements sur
              <span className="investInThaland">Invest In Thailand</span> en
              optant pour plusieurs propriétés afin de limiter l'exposition à
              des problèmes spécifiques à une seule propriété, tels que des
              défauts de paiement des locataires ou des évaluations négatives
              pouvant affecter votre investissement.
            </p>
          </div>
          {/* Second Column - Image (wrapped in Card) */}
          <div className="col-md-6 row justify-content-center align-items-start">
            <div style={{ width: "100%", maxWidth: "500px" }}>
              <Image imagePath={risk} />
            </div>
          </div>
        </div>
        <div className="row justify-content-center align-items-start">
          {" "}
          {/* First Column - Text */}
          <div className="col-md-12 custom-top-margin"></div>
          <h4 className="investInThalandColor">Revenus Variables</h4>
          <p>
            Bien que <span className="investInThaland">Invest In Thailand</span>{" "}
            fournisse des estimations de revenus locatifs bruts basées sur des
            informations tierces, veuillez noter que celles-ci ne sont pas
            garanties. Les loyers réels peuvent différer des estimations et il
            est possible que des périodes de non-occupation entraînent une
            interruption temporaire des revenus locatifs. Si un locataire ne
            respecte pas ses obligations de paiement, cela pourrait entraîner
            une baisse des encaissements et des liquidités disponibles pour les
            investisseurs.
          </p>
          <ul>
            <li>
              L'investissement immobilier est spéculatif, ce qui signifie que la
              valeur du bien peut baisser et que les revenus locatifs ne sont
              pas garantis.
            </li>
            <li>
              Les performances passées ne préjugent pas des performances
              futures.
            </li>
            <li>
              Les fluctuations du marché immobilier peuvent influencer
              l'évaluation de votre propriété.
            </li>
          </ul>
          <p>
            La valeur de votre investissement sur{" "}
            <span className="investInThaland">Invest In Thailand</span> peut
            aussi bien augmenter que diminuer, et il est essentiel de garder à
            l'esprit que les performances passées ne garantissent pas les
            performances futures. Les rendements locatifs et en capital
            dépendent de nombreux facteurs, tels que l'état du marché immobilier
            et l'évolution économique en général. Une dépréciation de la valeur
            de votre investissement peut être due à diverses raisons, telles
            qu'une baisse de la valeur sous-jacente de la propriété ou des
            problèmes qui peuvent affecter les futurs revenus locatifs. Les
            investissements immobiliers sont sensibles aux cycles économiques,
            et les valeurs peuvent varier en conséquence. De plus, les
            conditions économiques, politiques et juridiques peuvent également
            impacter les valeurs, tout comme pour d'autres classes d'actifs.
            Dans certains cas, des restrictions gouvernementales peuvent limiter
            la vente de propriétés à des propriétaires étrangers, ce qui peut
            restreindre la base d'acheteurs potentiels. En cas de ralentissement
            futur du marché immobilier, la valeur de la propriété pourrait être
            significativement affectée, entraînant potentiellement une perte
            partielle ou totale des revenus générés par votre investissement
            immobilier. Nous vous encourageons à évaluer soigneusement votre
            tolérance au risque dès le début de l'investissement. Si, pour une
            raison quelconque, l'opérateur cesse ses activités, vous pourriez
            perdre votre capital, encourir des frais ou faire face à des retards
            dans la liquidation de votre investissement.
          </p>
          <h4 className="investInThalandColor">
            Investissement en Actions Non Négociables
          </h4>
          <p>
            Veuillez noter que votre investissement ne vous rendra pas
            propriétaire direct de la propriété, mais vous détenez plutôt une
            participation dans une entité juridique qui en est le propriétaire.
            Étant donné que cette participation n'est pas cotée ou négociée, il
            s'agit d'un investissement "illiquide", c'est-à-dire qu'il peut être
            difficile de le vendre en raison du manque d'investisseurs
            intéressés. Soyez donc conscient que vous devrez probablement
            conserver votre investissement pendant toute la durée de
            l'opération.
          </p>
          <h4 className="investInThalandColor">Liquidité</h4>
          <p>
            Étant donné que l'immobilier est considéré comme un "actif
            illiquide", la vente de la propriété à la fin de la période
            d'investissement peut être difficile. Cela peut entraîner un délai
            pour récupérer votre capital ou même entraîner une vente à perte.
            Une fois que la possibilité de transférer des actions sera
            opérationnelle, vous pourrez mettre votre investissement en vente
            auprès d'autres utilisateurs. Cependant, il est possible qu'aucun
            acheteur ne soit intéressé à acheter votre participation à un prix
            que vous jugerez raisonnable, voire qu'aucun acheteur ne se
            manifeste du tout. Dans ce cas, vous devrez attendre la fin de la
            période d'investissement pour sortir de l'opération. Même à ce
            moment-là, la possibilité de sortir dépendra de la réalisation d'une
            transaction de vente de la propriété sous-jacente, un processus qui
            peut prendre plusieurs mois.
          </p>
          <h3>Clause de Non-responsabilité</h3>
          <p>
            <span className="investInThaland">Invest In Thailand</span> n'est
            pas un conseiller en investissement, et aucune évaluation n'est
            effectuée pour déterminer si un investissement convient à un
            investisseur particulier. Les informations fournies sont destinées à
            vous aider à prendre des décisions éclairées. Il est de votre
            responsabilité d'effectuer vos propres recherches et, en cas de
            doute, de consulter un conseiller financier indépendant.
          </p>
          <h4 className="investInThalandColor">Compte Dépositaire</h4>
          <p>
            Les fonds des clients sont détenus sur un compte client séparé gérés
            par <span className="investInThaland">Invest In Thailand</span>. Une
            vérification préalable des systèmes et des contrôles .
          </p>
        </div>
      </div>
    </div>
  );
};
