import React from "react";
import Button from "react-bootstrap/Button";
import ImageCardHold from "../Components/ImageCardHoldFlip";
import hold from "../Projects/Flip/Alba/1.jpg";
import FlipProcess from "../Components/FlipProcess";
import { Link } from "react-router-dom";

export const Flip = () => {
  return (
    <div>
      <div className="container mt-5">
        <div className="row justify-content-center align-items-start">
          <div className="col-md-6 custom-top-margin">
            <h2 style={{ margin: 30 }}>Achat / Revente</h2>
            <p style={{ margin: 50, fontSize: 15 }}></p>
            <h4>
              Nous acquérons, rénovons et vendons des biens exceptionnels.
            </h4>
            <br />
            <p>
              Le marché de l'immobilier en Thailand offre une opportunité
              exceptionnelle en raison de la demande croissante pour des
              propriétés modernes et contemporaines, qui dépasse l'offre
              actuelle de villas au style traditionnel thailandais.
            </p>
            <p>
              Nous visons à répondre à la demande croissante de propriétés
              modernes et contemporaines. Notre objectif est de créer une
              expérience résidentielle exclusive pour nos clients, en leur
              offrant des biens immobiliers qui répondent aux exigences
              actuelles du marché.
            </p>
            <p style={{ margin: 50, fontSize: 20 }}>
              <Link to="/signup">
                <Button variant="outline-primary">
                  Investir dès maintenant
                </Button>
              </Link>
              <Link to="/project">
                <Button variant="primary" style={{ marginLeft: "10px" }}>
                  Découvrir les projets
                </Button>
              </Link>
            </p>
          </div>
          {/* Second Column - Image (wrapped in Card) */}
          <div className="col-md-6 row justify-content-center align-items-start">
            <ImageCardHold
              imagePath={hold}
              width={60}
              flipHold="Flip"
              subTitle={"9-18 mois | Rendement visé +18% | À partir de $500"}
              name={"Villa Alba"}
              price={"391 000 € - 15 139 778 ฿"}
              progressBar={100}
            />
          </div>
        </div>
      </div>
      <div className="container mt-5">
        <div className="row justify-content-center align-items-start"></div>
        <FlipProcess />
      </div>
    </div>
  );
};
