import React, { useState, useEffect } from "react";
import { Button, Form, Row, Col, Alert } from "react-bootstrap";
import AccountMenu from "../Components/AccountNavigation";
import { useUser } from "../Contexts/UserContext";
import { AiOutlineLock } from "react-icons/ai";
import CountryDropdown from "../Components/CountryDropdown";
import ApiService from "../Services/ApiService";
import CountryDropDownPhone from "../Components/CountryDropDownPhone";

export const Account = () => {
  const { user, setUser } = useUser();
  const [showMessage, setShowMessage] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [messageText, setMessageText] = useState("");

  const [formData, setFormData] = useState({
    civility: user?.civility || "Monsieur",
    firstName: user?.firstName || "",
    lastName: user?.lastName || "",
    email: user?.email || "",
    birthday: user?.birthday || "",
    phoneNumber: user?.phoneNumber || "",
    nationality: user?.nationality,
    birthZipCode: user?.birthZipCode,
    birthCity: user?.birthCity,
    birthCountry: user?.birthCountry,
    fiscalCountry: user?.fiscalCountry,
    numberAddress: user?.numberAddress,
    roadAddress: user?.roadAddress,
    cityAddress: user?.cityAddress,
    zipCodeAddress: user?.zipCodeAddress,
    countryAddress: user?.countryAddress,
    errorMessages: {} as Record<string, string>,
    token: user?.token,
    validate: user?.validate,
    id: user?.id,
    companyName: user?.companyName,
    phoneIndicatif: user?.phoneIndicatif,
  });

  const handleChange = (name: string, value: string) => {
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleCountrySelection = (selectedCountry: string, field: string) => {
    if (field === "birthCountry") {
      setFormData((prevData) => ({
        ...prevData,
        birthCountry: selectedCountry,
      }));
    } else if (field === "fiscalCountry") {
      setFormData((prevData) => ({
        ...prevData,
        fiscalCountry: selectedCountry,
      }));
    } else if (field === "countryAddress") {
      setFormData((prevData) => ({
        ...prevData,
        countryAddress: selectedCountry,
      }));
    } else if (field === "nationality") {
      setFormData((prevData) => ({
        ...prevData,
        nationality: selectedCountry,
      }));
    }
  };

  // Effect to trigger fade-in and fade-out animations
  useEffect(() => {
    let timeoutId: NodeJS.Timeout | null = null;

    if (showMessage) {
      timeoutId = setTimeout(() => {
        setShowMessage(false);
      }, 3000);
    }

    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [showMessage]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    const newErrorMessages: Record<string, string> = {};

    // Validate required fields
    if (!formData.firstName) {
      newErrorMessages.firstName = "Ce champ est obligatoire.";
    }
    if (!formData.lastName) {
      newErrorMessages.lastName = "Ce champ est obligatoire.";
    }
    if (!formData.email) {
      newErrorMessages.email = "Ce champ est obligatoire.";
    }
    if (!formData.birthday) {
      newErrorMessages.birthday = "Ce champ est obligatoire.";
    }
    if (!formData.phoneNumber) {
      newErrorMessages.phoneNumber = "Ce champ est obligatoire.";
    }
    if (!formData.nationality) {
      newErrorMessages.nationality = "Ce champ est obligatoire.";
    }
    if (!formData.birthZipCode) {
      newErrorMessages.birthZipCode = "Ce champ est obligatoire.";
    }
    if (!formData.birthCity) {
      newErrorMessages.birthCity = "Ce champ est obligatoire.";
    }
    if (!formData.birthCountry) {
      newErrorMessages.birthCountry = "Ce champ est obligatoire.";
    }
    if (!formData.fiscalCountry) {
      newErrorMessages.fiscalCountry = "Ce champ est obligatoire.";
    }
    if (!formData.numberAddress) {
      newErrorMessages.numberAddress = "Ce champ est obligatoire.";
    }
    if (!formData.roadAddress) {
      newErrorMessages.roadAddress = "Ce champ est obligatoire.";
    }
    if (!formData.cityAddress) {
      newErrorMessages.cityAddress = "Ce champ est obligatoire.";
    }
    if (!formData.zipCodeAddress) {
      newErrorMessages.zipCodeAddress = "Ce champ est obligatoire.";
    }
    if (!formData.countryAddress) {
      newErrorMessages.countryAddress = "Ce champ est obligatoire.";
    }

    setFormData((prevData) => ({
      ...prevData,
      errorMessages: newErrorMessages,
    }));

    if (formData.phoneIndicatif == null) {
      formData.phoneIndicatif = "+33";
    }

    // If all required fields are filled
    if (Object.keys(newErrorMessages).length === 0) {
      const authToken = user.token;

      const apiService = new ApiService<any>(
        process.env.REACT_APP_CF_API_URL + "/user",
        authToken
      );

      try {
        const response = await apiService.post("", formData);
        const responseData = await response.json();

        if (response.ok && responseData) {
          setIsSuccess(true);
          setMessageText("Enregistrement réussi !");

          setUser(formData);
        } else {
          setIsSuccess(false);
          setMessageText("Une erreur s'est produite lors de l'enregistrement.");
        }
        setShowMessage(true);

        // Set a timer to hide the message after 3 seconds
        setTimeout(() => {
          setShowMessage(false);
        }, 3000);
      } catch (error) {
        console.error("An error occurred:", error);
      }
    }
  };

  return (
    <div className="container">
      <div className="row">
        {/* Colonne pour le menu */}
        <div className="col-md-2">
          <h1>&nbsp;</h1>
          <AccountMenu />
        </div>

        {/* Colonne pour le formulaire */}
        <div className="col-md-10">
          <h1>Mon compte</h1>
          <br />
          <h2>Informations personnelles</h2>
          <br />
          <Form className="account-form" onSubmit={handleSubmit}>
            <Row className="mb-3">
              {/* Civilité */}
              <Col md={6}>
                <Form.Group controlId="civility">
                  <Form.Label className="font-weight-bold">Civilité</Form.Label>
                  <Form.Control
                    as="select"
                    name="civility"
                    value={formData.civility}
                    onChange={(e) => handleChange("civility", e.target.value)}
                  >
                    <option>Monsieur</option>
                    <option>Madame</option>
                  </Form.Control>
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group controlId="companyName">
                  <Form.Label className="font-weight-bold">
                    Company name
                  </Form.Label>
                  <div className="input-group mb-3">
                    <Form.Control
                      type="text"
                      name="companyName"
                      value={formData.companyName}
                      onChange={(e) =>
                        handleChange("companyName", e.target.value)
                      }
                      style={{ backgroundColor: "white" }}
                    />
                  </div>
                  {formData.errorMessages.lastName && (
                    <Alert variant="danger">
                      {formData.errorMessages.lastName}
                    </Alert>
                  )}
                </Form.Group>
              </Col>
            </Row>

            {/* Champs Prénom et Nom */}
            <Row className="mb-3">
              <Col md={6}>
                <Form.Group controlId="firstName">
                  <Form.Label className="font-weight-bold">
                    Prénom<span style={{ color: "red" }}>*</span>
                  </Form.Label>
                  <div className="input-group mb-3">
                    <Form.Control
                      type="text"
                      name="firstName"
                      value={formData.firstName}
                      onChange={(e) =>
                        handleChange("firstName", e.target.value)
                      }
                      disabled
                      readOnly
                      style={{ backgroundColor: "white" }}
                    />
                    <div className="input-group-prepend">
                      <span className="input-group-text" id="basic-addon1">
                        <AiOutlineLock style={{ fontSize: "25px" }} />
                      </span>
                    </div>
                  </div>
                  {formData.errorMessages.firstName && (
                    <Alert variant="danger">
                      {formData.errorMessages.firstName}
                    </Alert>
                  )}
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group controlId="lastName">
                  <Form.Label className="font-weight-bold">
                    Nom<span style={{ color: "red" }}>*</span>
                  </Form.Label>
                  <div className="input-group mb-3">
                    <Form.Control
                      type="text"
                      name="lastName"
                      value={formData.lastName}
                      onChange={(e) => handleChange("lastName", e.target.value)}
                      disabled
                      readOnly
                      style={{ backgroundColor: "white" }}
                    />
                    <div className="input-group-prepend">
                      <span className="input-group-text" id="basic-addon1">
                        <AiOutlineLock style={{ fontSize: "25px" }} />
                      </span>
                    </div>
                  </div>
                  {formData.errorMessages.lastName && (
                    <Alert variant="danger">
                      {formData.errorMessages.lastName}
                    </Alert>
                  )}
                </Form.Group>
              </Col>
            </Row>

            <Row className="mb-3">
              <Col md={6}>
                <Form.Group controlId="email">
                  <Form.Label className="font-weight-bold">
                    Email<span style={{ color: "red" }}>*</span>
                  </Form.Label>
                  <div className="input-group mb-3">
                    <Form.Control
                      type="email"
                      name="email"
                      value={formData.email}
                      onChange={(e) => handleChange("email", e.target.value)}
                      disabled
                      readOnly
                      style={{ backgroundColor: "white" }}
                    />
                    <div className="input-group-prepend">
                      <span className="input-group-text" id="basic-addon1">
                        <AiOutlineLock style={{ fontSize: "25px" }} />
                      </span>
                    </div>
                  </div>
                  {formData.errorMessages.email && (
                    <Alert variant="danger">
                      {formData.errorMessages.email}
                    </Alert>
                  )}
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group controlId="birthday">
                  <Form.Label className="font-weight-bold">
                    Date de naissance<span style={{ color: "red" }}>*</span>
                  </Form.Label>
                  <Form.Control
                    type="date"
                    name="birthday"
                    value={
                      formData.birthday
                        ? formData.birthday.split("T")[0]
                        : "2023-01-01"
                    }
                    onChange={(e) => handleChange("birthday", e.target.value)}
                  />
                  {formData.errorMessages.birthday && (
                    <Alert variant="danger">
                      {formData.errorMessages.birthday}
                    </Alert>
                  )}
                </Form.Group>
              </Col>
            </Row>

            <Row className="mb-3">
              <Col md={6}>
                <Form.Group controlId="phoneNumber">
                  <Form.Label className="font-weight-bold">
                    Numéro de téléphone<span style={{ color: "red" }}>*</span>
                  </Form.Label>
                  <div
                    className="d-flex align-items-center"
                    style={{ marginTop: "-2px" }}
                  >
                    <CountryDropDownPhone
                      defaultValue={user.phoneIndicatif}
                      onSelectCountry={(phoneIndicatif: any) =>
                        handleChange("phoneIndicatif", phoneIndicatif)
                      }
                    />
                    <Form.Control
                      type="text"
                      name="phoneNumber"
                      value={formData.phoneNumber}
                      onChange={(e) =>
                        handleChange("phoneNumber", e.target.value)
                      }
                      className="ml-2 mb-0" // Utilisez mb-0 pour supprimer la marge en bas
                    />
                  </div>
                  {formData.errorMessages.phoneNumber && (
                    <Alert variant="danger">
                      {formData.errorMessages.phoneNumber}
                    </Alert>
                  )}
                </Form.Group>
              </Col>

              <Col md={6}>
                <Form.Group controlId="nationality">
                  <Form.Label className="font-weight-bold">
                    Nationalité<span style={{ color: "red" }}>*</span>
                  </Form.Label>

                  <CountryDropdown
                    onCountrySelect={(selectedCountry) =>
                      handleCountrySelection(selectedCountry, "nationality")
                    }
                    initialCountry={formData.nationality || ""}
                  />
                  {formData.errorMessages.nationality && (
                    <Alert variant="danger">
                      {formData.errorMessages.nationality}
                    </Alert>
                  )}
                </Form.Group>
              </Col>
            </Row>

            <Row className="mb-3">
              <Col md={6}>
                <Form.Group controlId="birthZipCode">
                  <Form.Label className="font-weight-bold">
                    Code postal de naissance
                    <span style={{ color: "red" }}>*</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="birthZipCode"
                    value={formData.birthZipCode}
                    onChange={(e) =>
                      handleChange("birthZipCode", e.target.value)
                    }
                  />
                  {formData.errorMessages.birthZipCode && (
                    <Alert variant="danger">
                      {formData.errorMessages.birthZipCode}
                    </Alert>
                  )}
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group controlId="birthCity">
                  <Form.Label className="font-weight-bold">
                    Ville de naissance<span style={{ color: "red" }}>*</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="birthCity"
                    value={formData.birthCity}
                    onChange={(e) => handleChange("birthCity", e.target.value)}
                  />
                  {formData.errorMessages.birthCity && (
                    <Alert variant="danger">
                      {formData.errorMessages.birthCity}
                    </Alert>
                  )}
                </Form.Group>
              </Col>
            </Row>

            <Row className="mb-3">
              <Col md={6}>
                <Form.Group controlId="birthCountry">
                  <Form.Label className="font-weight-bold">
                    Pays de naissance<span style={{ color: "red" }}>*</span>
                  </Form.Label>
                  <CountryDropdown
                    onCountrySelect={(selectedCountry) => {
                      handleCountrySelection(selectedCountry, "birthCountry");
                    }}
                    initialCountry={formData.birthCountry || ""}
                  />

                  {formData.errorMessages.birthCountry && (
                    <Alert variant="danger">
                      {formData.errorMessages.birthCountry}
                    </Alert>
                  )}
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group controlId="fiscalCountry">
                  <Form.Label className="font-weight-bold">
                    Pays de résidence fiscale
                    <span style={{ color: "red" }}>*</span>
                  </Form.Label>
                  <CountryDropdown
                    onCountrySelect={(selectedCountry) =>
                      handleCountrySelection(selectedCountry, "fiscalCountry")
                    }
                    initialCountry={formData.fiscalCountry || ""}
                  />
                  {formData.errorMessages.fiscalCountry && (
                    <Alert variant="danger">
                      {formData.errorMessages.fiscalCountry}
                    </Alert>
                  )}
                </Form.Group>
              </Col>
            </Row>

            <hr />
            <h2>Coordonnées</h2>
            <br />

            <Row className="mb-3">
              <Col md={6}>
                <Form.Group controlId="numberAddress">
                  <Form.Label className="font-weight-bold">
                    Numéro<span style={{ color: "red" }}>*</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="numberAddress"
                    value={formData.numberAddress}
                    onChange={(e) =>
                      handleChange("numberAddress", e.target.value)
                    }
                  />
                  {formData.errorMessages.numberAddress && (
                    <Alert variant="danger">
                      {formData.errorMessages.numberAddress}
                    </Alert>
                  )}
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group controlId="roadAddress">
                  <Form.Label className="font-weight-bold">
                    Voie<span style={{ color: "red" }}>*</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="roadAddress"
                    value={formData.roadAddress}
                    onChange={(e) =>
                      handleChange("roadAddress", e.target.value)
                    }
                  />
                  {formData.errorMessages.roadAddress && (
                    <Alert variant="danger">
                      {formData.errorMessages.roadAddress}
                    </Alert>
                  )}
                </Form.Group>
              </Col>
            </Row>

            <Row className="mb-3">
              <Col md={6}>
                <Form.Group controlId="cityAddress">
                  <Form.Label className="font-weight-bold">
                    Ville<span style={{ color: "red" }}>*</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="cityAddress"
                    value={formData.cityAddress}
                    onChange={(e) =>
                      handleChange("cityAddress", e.target.value)
                    }
                  />
                  {formData.errorMessages.cityAddress && (
                    <Alert variant="danger">
                      {formData.errorMessages.cityAddress}
                    </Alert>
                  )}
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group controlId="zipCodeAddress">
                  <Form.Label className="font-weight-bold">
                    Code postal<span style={{ color: "red" }}>*</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="zipCodeAddress"
                    value={formData.zipCodeAddress}
                    onChange={(e) =>
                      handleChange("zipCodeAddress", e.target.value)
                    }
                  />
                  {formData.errorMessages.zipCodeAddress && (
                    <Alert variant="danger">
                      {formData.errorMessages.zipCodeAddress}
                    </Alert>
                  )}
                </Form.Group>
              </Col>
            </Row>

            <Row className="mb-3">
              <Col md={6}>
                <Form.Group controlId="countryAddress">
                  <Form.Label className="font-weight-bold">
                    Pays<span style={{ color: "red" }}>*</span>
                  </Form.Label>

                  <CountryDropdown
                    onCountrySelect={(selectedCountry) =>
                      handleCountrySelection(selectedCountry, "countryAddress")
                    }
                    initialCountry={formData.countryAddress || ""}
                  />
                  {formData.errorMessages.countryAddress && (
                    <Alert variant="danger">
                      {formData.errorMessages.countryAddress}
                    </Alert>
                  )}
                </Form.Group>
              </Col>
            </Row>

            <div className="form-group align-self-end">
              <Button type="submit" variant="primary">
                Enregistrer
              </Button>
            </div>
          </Form>
          {/* Full-screen overlay */}
          <div className={`overlay ${showMessage ? "visible" : ""}`}></div>
          {/* Conditionally render the fadeIn effect and message */}
          <div className={`fade-in ${showMessage ? "visible" : ""}`}>
            <p
              className={`message-text ${
                isSuccess ? "success-message" : "error-message"
              }`}
            >
              {messageText}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
