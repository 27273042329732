import React, { useState } from "react";
import signup from "../Images/signup.jpg";
import Image from "../Components/Image";
import { OverlayTrigger, Popover, Button } from "react-bootstrap";
import { BsFillPersonFill, BsPerson } from "react-icons/bs";
import { MdAlternateEmail } from "react-icons/md";
import { RiLockPasswordLine, RiLockPasswordFill } from "react-icons/ri";
import ApiService from "../Services/ApiService";

export const Signup = () => {
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertStyle, setAlertStyle] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const [acceptedCGU, setAcceptedCGU] = useState(false);
  const [errors, setErrors] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    passwordConfirm: "",
    cgu: "",
  });

  const validateEmail = (email: string) => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailPattern.test(email);
  };

  const renderErrorPopover = (error: string) => (
    <Popover id="popover-basic">
      <Popover.Body>{error}</Popover.Body>
    </Popover>
  );

  const handleCreateAccount = async () => {
    const newErrors = {
      firstName: firstName ? "" : "Le prénom est requis.",
      lastName: lastName ? "" : "Le nom est requis.",
      email: validateEmail(email)
        ? ""
        : "Veuillez fournir une adresse email valide.",
      password:
        password.length >= 12
          ? ""
          : "Le mot de passe doit contenir au moins 12 caractères.",
      passwordConfirm:
        password === passwordConfirm
          ? ""
          : "Les mots de passe ne correspondent pas.",
      cgu: acceptedCGU ? "" : "Vous devez accepter les conditions générales.",
    };

    setErrors(newErrors);

    if (
      Object.values(newErrors).some((error: string) => error !== "") ||
      !acceptedCGU
    ) {
      return;
    }

    const formData = {
      firstName,
      lastName,
      email,
      password: password,
    };

    const apiService = new ApiService<any>(
      process.env.REACT_APP_CF_API_URL + "/token"
    );

    try {
      const response = await (await apiService.post("signup", formData)).json();

      if (response.errorId === -1) {
        setAlertMessage("Utilisateur déjà enregistré.");
        setAlertStyle("danger");
        setShowAlert(true);

        setTimeout(() => {
          setShowAlert(false);
        }, 3000);
      } else {
        setAlertMessage(
          "Compte créé avec succès. Un email vous a été envoyé pour validation."
        );
        setAlertStyle("success");
        setShowAlert(true);

        setTimeout(() => {
          setShowAlert(false);
          //window.location.href = "/";
        }, 5000);
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };

  return (
    <div>
      <div className="container mt-5">
        <div className="row justify-content-center align-items-start">
          {/* First Column - Text */}
          <div className="col-md-6 custom-top-margin">
            <h2 style={{ margin: 30 }}>Créer mon compte</h2>

            <form className="account-form">
              {/* First Name */}
              <div className="form-group custom-form-group">
                <label htmlFor="firstName">Prénom:</label>
                <OverlayTrigger
                  trigger={["focus", "hover"]}
                  placement="bottom"
                  overlay={renderErrorPopover(errors.firstName)}
                >
                  <div className="input-group mb-3">
                    <div className="input-group-prepend">
                      <span className="input-group-text" id="basic-addon1">
                        <BsFillPersonFill style={{ fontSize: "25px" }} />
                      </span>
                    </div>

                    <input
                      type="text"
                      id="firstName"
                      className={`form-control ${
                        errors.firstName ? "is-invalid" : ""
                      }`}
                      value={firstName}
                      onChange={(e) => setFirstName(e.target.value)}
                    />
                  </div>
                </OverlayTrigger>
              </div>

              {/* Last Name */}
              <div className="form-group custom-form-group">
                <label htmlFor="lastName">Nom:</label>
                <OverlayTrigger
                  trigger={["focus", "hover"]}
                  placement="bottom"
                  overlay={renderErrorPopover(errors.lastName)}
                >
                  <div className="input-group mb-3">
                    <div className="input-group-prepend">
                      <span className="input-group-text" id="basic-addon1">
                        <BsPerson style={{ fontSize: "25px" }} />
                      </span>
                    </div>

                    <input
                      type="text"
                      id="lastName"
                      className={`form-control ${
                        errors.lastName ? "is-invalid" : ""
                      }`}
                      value={lastName}
                      onChange={(e) => setLastName(e.target.value)}
                    />
                  </div>
                </OverlayTrigger>
              </div>

              {/* Email */}
              <div className="form-group custom-form-group">
                <label htmlFor="email">Email:</label>
                <OverlayTrigger
                  trigger={["focus", "hover"]}
                  placement="bottom"
                  overlay={renderErrorPopover(errors.email)}
                >
                  <div className="input-group mb-3">
                    <div className="input-group-prepend">
                      <span className="input-group-text" id="basic-addon1">
                        <MdAlternateEmail style={{ fontSize: "25px" }} />
                      </span>
                    </div>

                    <input
                      type="text"
                      id="email"
                      className={`form-control ${
                        errors.email ? "is-invalid" : ""
                      }`}
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>
                </OverlayTrigger>
              </div>

              {/* Password */}
              <div className="form-group custom-form-group">
                <label htmlFor="password">
                  Mot de passe (12 caractères minimum):
                </label>
                <OverlayTrigger
                  trigger={["focus", "hover"]}
                  placement="bottom"
                  overlay={renderErrorPopover(errors.password)}
                >
                  <div className="input-group mb-3">
                    <div className="input-group-prepend">
                      <span className="input-group-text" id="basic-addon1">
                        <RiLockPasswordLine style={{ fontSize: "25px" }} />
                      </span>
                    </div>
                    <input
                      type="password"
                      id="password"
                      className={`form-control ${
                        errors.password ? "is-invalid" : ""
                      }`}
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                  </div>
                </OverlayTrigger>
              </div>

              {/* Password Confirm */}
              <div className="form-group custom-form-group">
                <label htmlFor="passwordConfirm">
                  Confirmation du mot de passe:
                </label>
                <OverlayTrigger
                  trigger={["focus", "hover"]}
                  placement="bottom"
                  overlay={renderErrorPopover(errors.passwordConfirm)}
                >
                  <div className="input-group mb-3">
                    <div className="input-group-prepend">
                      <span className="input-group-text" id="basic-addon1">
                        <RiLockPasswordFill style={{ fontSize: "25px" }} />
                      </span>
                    </div>
                    <input
                      type="password"
                      id="passwordConfirm"
                      className={`form-control ${
                        errors.passwordConfirm ? "is-invalid" : ""
                      }`}
                      value={passwordConfirm}
                      onChange={(e) => setPasswordConfirm(e.target.value)}
                    />
                  </div>
                </OverlayTrigger>
              </div>

              {/* CGU Acceptance Checkbox */}
              <div className="form-group custom-form-group">
                <input
                  type="checkbox"
                  id="chkCGU"
                  checked={acceptedCGU}
                  onChange={() => setAcceptedCGU(!acceptedCGU)}
                  style={{ marginRight: "10px" }}
                />
                <label htmlFor="lblCGU">
                  J'ai pris connaissance des{" "}
                  <a href="#/cgu" target="_blank" rel="noopener noreferrer">
                    conditions générales
                  </a>
                </label>
                {errors.cgu && (
                  <div
                    style={{
                      color: "red",
                      marginLeft: "10px",
                      fontSize: "12px",
                    }}
                  >
                    {errors.cgu}
                  </div>
                )}
              </div>
              <br />
              <br />
              {showAlert && (
                <div
                  className={`alert alert-${alertStyle} text-center`}
                  role="alert"
                >
                  {alertMessage}
                </div>
              )}
              <div className="form-group align-self-end">
                <Button variant="primary" onClick={handleCreateAccount}>
                  Créer mon compte
                </Button>
              </div>
            </form>
          </div>
          <div className="col-md-6 row justify-content-center align-items-start">
            <Image imagePath={signup} />
          </div>
        </div>
      </div>
      <div className="container mt-5">
        <div className="row justify-content-center align-items-start"></div>
        <div className="col-md-6 row justify-content-center align-items-start"></div>
      </div>
    </div>
  );
};
