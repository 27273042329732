import React from "react";
import Testimonials from "../Components/Testimonials";

export const Testimony = () => {
  return (
    <div>
      <Testimonials />
      <div className="container mt-5">
        <div className="row justify-content-center align-items-start"></div>
        {/* Second Column - Image (wrapped in Card) */}
        <div className="col-md-6 row justify-content-center align-items-start"></div>
      </div>
    </div>
  );
};
