import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import CountriesDropDownList from "../LocalData/CountriesDropDownList.json";

interface Country {
  name: string;
}

interface CountryDropdownProps {
  onCountrySelect: (selectedCountry: string) => void;
  initialCountry?: string;
}

function CountryDropdown({
  onCountrySelect,
  initialCountry = "",
}: CountryDropdownProps) {
  const [countryNames, setCountryNames] = useState<string[]>([]);
  const [selectedCountry, setSelectedCountry] =
    useState<string>(initialCountry);

  useEffect(() => {
    const sortedCountryNames = CountriesDropDownList.map(
      (country) => country.name
    ).sort();
    setCountryNames(sortedCountryNames);
  }, []);

  const handleCountryChange = (event: React.ChangeEvent<EventTarget>) => {
    const target = event.target as HTMLSelectElement;
    const selectedCountry = target.value;
    setSelectedCountry(selectedCountry);
    onCountrySelect(selectedCountry);
  };

  return (
    <Form>
      <Form.Group controlId="countrySelect">
        <Form.Control
          as="select"
          value={selectedCountry}
          onChange={handleCountryChange}
        >
          <option></option>
          {countryNames.map((countryName, index) => (
            <option key={index} value={countryName}>
              {countryName}
            </option>
          ))}
        </Form.Control>
      </Form.Group>
    </Form>
  );
}

export default CountryDropdown;
