import React from "react";
import legalNotice from "../Images/legalNotice.jpg";
import Image from "../Components/Image";

export const LegalNotice = () => {
  return (
    <div>
      <div className="container mt-5">
        <div className="row justify-content-center align-items-start">
          {" "}
          {/* First Column - Text */}
          <div className="col-md-6 custom-top-margin">
            <h2 style={{ margin: 30 }}>Mentions legales</h2>

            <p>
              <b>Société</b>: thai projects pro
              <br />
              <b>Numero societe</b>: 0845566003291
              <br />
              <b>Email</b>:{" "}
              <a href="mailto:contact@edenroc-immobilier.com?subject=[Invest in Thaland] - Informations&body=Bonjour">
                contact@edenroc-immobilier.com
              </a>
            </p>
          </div>
          {/* Second Column - Image (wrapped in Card) */}
          <div className="col-md-6 row justify-content-center align-items-start">
            <div style={{ width: "100%", maxWidth: "500px" }}>
              <Image imagePath={legalNotice} />
            </div>
          </div>
        </div>
      </div>
      <div className="container mt-5">
        <div className="row justify-content-center align-items-start"></div>
        {/* Second Column - Image (wrapped in Card) */}
        <div className="col-md-6 row justify-content-center align-items-start"></div>
      </div>
    </div>
  );
};
