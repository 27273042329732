import React, { useState } from "react";

interface DropdownListProps {
  onChange: (selectedValue: number) => void;
}

const SharesDropdown: React.FC<DropdownListProps> = ({ onChange }) => {
  const [selectedValue, setSelectedValue] = useState<number | undefined>(
    undefined
  );

  const handleSelectChange = (newValue: number) => {
    setSelectedValue(newValue);
    onChange(newValue);
  };

  return (
    <>
      <b>Sélection du nombre de parts souhaitées : </b>{" "}
      <div className="btn-group">
        <button
          type="button"
          className="btn btn-primary dropdown-toggle"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
          data-bs-toggle="dropdown"
        >
          {selectedValue !== undefined ? selectedValue : 0}
        </button>
        <div className="dropdown-menu">
          {[1, 2, 3, 4, 5, 6].map((value) => (
            <a
              key={value}
              className="dropdown-item"
              onClick={() => handleSelectChange(value)} // Utilisez la fonction handleSelectChange ici
            >
              {value}
            </a>
          ))}
        </div>
      </div>
    </>
  );
};

export default SharesDropdown;
