import React from "react";
import cgu from "../Images/cgu.jpg";
import Image from "../Components/Image";

export const Cgu = () => {
  return (
    <div>
      <div className="container mt-5">
        <div className="row justify-content-center align-items-start">
          {" "}
          {/* First Column - Text */}
          <div className="col-md-6 custom-top-margin">
            <h2 style={{ margin: 30 }}>Conditions générales d'utilisation</h2>

            <p>
              <span className="investInThaland">Invest In Thailand</span> édite
              le site internet accessible à l'adresse
              <a href="https://investinthailand.co" target="_blank">
                {" "}
                https://investinthailand.co
              </a>{" "}
              <br />
              Le Site est destiné à promouvoir la marque{" "}
              <span className="investInThaland">Invest In Thailand</span> et la
              Plateforme en fournissant des informations sur le site, la
              Plateforme et le secteur de l'immobilier et de l'investissement.
              La Plateforme permet aux internautes de créer un compte membre
              afin d'accéder à diverses fonctionnalités visant à investir dans
              les projets d'immobilier commercial de{" "}
              <span className="investInThaland">Invest In Thailand</span>.<br />
              <br />
              Les investissements se font par l'achat de titres financiers
              appelés "Obligations". Les membres inscrits sur la Plateforme
              peuvent étudier les projets d'investissement proposés, leur
              rendement et recevoir des loyers en fonction de leurs
              investissements. Un Utilisateur est un internaute visitant le Site
              et/ou utilisant la Plateforme, tandis qu'un Membre est une
              personne physique majeure en France qui s'inscrit sur la
              Plateforme dans le but d'investir dans les projets et de percevoir
              des loyers. Les présentes Conditions Générales d'Utilisation (CGU)
              définissent les règles pour l'utilisation du Site et de la
              Plateforme par les Utilisateurs. En utilisant le Site ou en
              s'inscrivant sur la Plateforme, l'Utilisateur accepte les CGU.
              <br />
              <br /> Il est important de noter que les CGU ne régissent pas les
              relations contractuelles entre{" "}
              <span className="investInThaland">Invest In Thailand</span> et les
              Membres concernant les investissements réalisés sur la Plateforme.
              Ces relations sont encadrées par un Contrat d'Investissement.
            </p>
          </div>
          {/* Second Column - Image (wrapped in Card) */}
          <div className="col-md-6 row justify-content-center align-items-start">
            <div style={{ width: "100%", maxWidth: "500px" }}>
              <Image imagePath={cgu} />
            </div>
          </div>
        </div>
        <div className="row justify-content-center align-items-start">
          {" "}
          {/* First Column - Text */}
          <div className="col-md-12 custom-top-margin">
            <h4 className="investInThalandColor">Article 1 - Définitions</h4>
            <p>
              <ul>
                <li>
                  Compte membre : compte personnel créé par un Membre sur la
                  Plateforme pour accéder aux fonctionnalités.
                </li>
                <li>
                  Conditions Générales d'Utilisation (CGU) : stipulations
                  régissant l'utilisation du Site et de la Plateforme.
                </li>
                <li>
                  Contrat d'investissement : contrat entre{" "}
                  <span className="investInThaland">Invest In Thailand</span> et
                  un Membre régissant leurs relations contractuelles pour un
                  Investissement sur la Plateforme.
                </li>
                <li>
                  Données à caractère personnel : informations relatives à une
                  personne physique identifiable.
                </li>
                <li>
                  Fonctionnalités de la Plateforme : fonctionnalités accessibles
                  par les Membres depuis leur Compte sur la Plateforme.
                </li>
                <li>
                  Fonctionnalités du Site : fonctionnalités accessibles par tout
                  Utilisateur sur le Site.
                </li>
                <li>
                  Identifiants de connexion : login et mot de passe de chaque
                  Membre pour se connecter à la Plateforme.
                </li>
                <li>
                  Investissement : opération financière d'achat d'Obligations.
                </li>
                <li>
                  Loyer : montant du loyer versé par un locataire et redistribué
                  aux Membres en fonction de leur Investissement.
                </li>
                <li>
                  Membre : internaute inscrit sur la Plateforme et disposant
                  d'un compte.
                </li>
                <li>
                  Plateforme : plateforme web éditée par{" "}
                  <span className="investInThaland">Invest In Thailand</span> et
                  accessible aux Membres inscrits.
                </li>
                <li>
                  Projets : actifs immobiliers commerciaux proposés aux Membres
                  sur la Plateforme pour investissement.
                </li>
                <li>
                  Obligations : titres émis lors de l'Investissement, avec
                  droits à une part du loyer versé sous forme de coupon et
                  d'éventuelle plus-value lors de la cession de l'actif.
                </li>
                <li>
                  Utilisateur : internaute naviguant sur le Site et/ou la
                  Plateforme.
                </li>
              </ul>
            </p>
            <h4 className="investInThalandColor">Article 2 - Objet</h4>
            <p>
              Les CGU définissent les modalités d'utilisation du Site et de la
              Plateforme par les Utilisateurs.{" "}
              <span className="investInThaland">Invest In Thailand</span> peut
              modifier les CGU avec notification aux Utilisateurs. Les
              Utilisateurs sont réputés accepter la nouvelle version en
              continuant à utiliser le Site et la Plateforme.{" "}
            </p>
            <p>
              Les CGU ne régissent pas les relations contractuelles pour les
              investissements réalisés sur la Plateforme. Ces relations sont
              encadrées par le Contrat d'Investissement.
            </p>
            <h4 className="investInThalandColor">
              Article 3 - Description des fonctionnalités du site
            </h4>
            <p>
              Le Site est une vitrine pour promouvoir la marque{" "}
              <span className="investInThaland">Invest In Thailand</span> et la
              Plateforme. Les Utilisateurs peuvent accéder à divers contenus et
              informations liés à{" "}
              <span className="investInThaland">Invest In Thailand</span>, ses
              activités d'investissement immobilier, etc. Ils peuvent également
              s'inscrire à la newsletter et accéder à un blog ou forum.
            </p>
            <h4 className="investInThalandColor">
              Article 4 - Description des fonctionnalités de la plateforme
            </h4>
            <p>
              La Plateforme permet aux Membres de créer un compte personnel,
              d'accéder à des contenus personnalisés, de découvrir de nouveaux
              projets immobiliers, d'investir dans ces projets via un Contrat
              d'Investissement, de suivre leurs investissements, de bénéficier
              d'un support en ligne et d'accéder à un forum d'échanges.
            </p>
            <h4 className="investInThalandColor">
              Article 5 - Accès et utilisation du site et de la plateforme
            </h4>
            <p>
              Le Site est accessible via une connexion Internet. Les
              Utilisateurs s'engagent à utiliser le Site et la Plateforme
              conformément aux CGU et au Code de bonnes conduites.
            </p>
            <p>
              {" "}
              L'inscription à la Plateforme est gratuite et ouverte aux
              personnes majeures de plus de 18 ans.{" "}
              <span className="investInThaland">Invest In Thailand</span> peut
              proposer des fonctionnalités payantes sur la Plateforme.
            </p>
            <h4 className="investInThalandColor">
              Article 6 - Inscription et connexion à la plateforme
            </h4>
            <p>
              Les Utilisateurs peuvent créer leur Compte sur la Plateforme en
              fournissant des Identifiants de connexion et des informations
              personnelles. Le processus de validation est obligatoire pour
              accéder aux fonctionnalités d'investissement.{" "}
            </p>
            <p>
              Les Membres peuvent se connecter à leur Compte en utilisant leurs
              Identifiants de connexion. Ces Identifiants sont personnels et
              confidentiels.
            </p>
            <h4 className="investInThalandColor">
              Article 7 - Investissements sur la plateforme
            </h4>
            <p>
              Les Membres peuvent investir dans les Projets disponibles sur la
              Plateforme en achetant des Obligations. Les conditions
              d'Investissement et de perception des loyers sont définies dans le
              Contrat d'Investissement.{" "}
            </p>
            <p>
              Les Membres sont informés des risques associés à leurs
              investissements et sont responsables de la gestion de leur
              patrimoine. La Plateforme ne propose pas de marché secondaire pour
              la revente des Obligations
            </p>
            <p>
              {" "}
              Il est précisé que
              <span className="investInThaland">Invest In Thailand</span> n’agit
              pas en qualité d’intermédiaire en Investissements Participatifs ou
              de conseiller en Investissement Participatif et n’aura pas
              vocation à évaluer la situation financière des Membres ni la
              compatibilité de leur situation avec les Investissements proposés
              sur la Plateforme.
            </p>
            <h4 className="investInThalandColor">Article 8 - Responsabilité</h4>
            <p>
              <span className="investInThaland">Invest In Thailand</span>{" "}
              s’engage à mettre en œuvre tous les moyens nécessaires pour
              assurer la disponibilité du Site et de la Plateforme et de leurs
              Fonctionnalités.
            </p>
            <p>
              {" "}
              <span className="investInThaland">Invest In Thailand</span> mettra
              tout en œuvre pour assurer la sécurité du Site et de la
              Plateforme, en déployant les mesures de sécurité nécessaires à cet
              effet.{" "}
            </p>
            <p>
              La responsabilité de{" "}
              <span className="investInThaland">Invest In Thailand</span> ne
              saurait être engagée en cas de dysfonctionnement ou
              d’indisponibilité du Site et/ou de la Plateforme, pour quelque
              cause que ce soit, et notamment en cas de force majeure, de
              problème technique, de maintenance, de défaillance des réseaux de
              communications électroniques, etc.{" "}
            </p>
            <p>
              {" "}
              <span className="investInThaland">Invest In Thailand</span> ne
              saurait en aucun cas être tenue responsable des difficultés
              techniques rencontrées par l’Utilisateur ou le Membre lors de
              l’accès ou de la navigation sur le Site et/ou la Plateforme.{" "}
            </p>
            <p>
              {" "}
              <span className="investInThaland">Invest In Thailand</span> ne
              saurait non plus être tenue responsable des éventuelles
              conséquences sur la connexion de l’Utilisateur et/ou du Membre au
              Site et/ou à la Plateforme.{" "}
            </p>
            <p>
              {" "}
              <span className="investInThaland">Invest In Thailand</span> se
              réserve le droit de suspendre, d’interrompre ou de restreindre
              l’accès à tout ou partie du Site et/ou de la Plateforme pour des
              raisons de sécurité, pour effectuer des opérations de maintenance,
              ou pour toute autre raison jugée nécessaire, sans que cela ne
              puisse donner lieu à une quelconque indemnisation ou compensation
              envers l’Utilisateur ou le Membre.{" "}
            </p>
            <p>
              {" "}
              <span className="investInThaland">Invest In Thailand</span> ne
              pourra être tenue responsable des éventuels dommages directs ou
              indirects subis par l’Utilisateur et/ou le Membre lors de
              l’utilisation du Site et/ou de la Plateforme, notamment en cas
              d’Investissement sur la Plateforme.
            </p>
            <p>
              Les Membres s’engagent à respecter les dispositions des présentes
              CGU et du Code de bonnes conduites et à ne pas utiliser la
              Plateforme de manière illicite ou contraire à l’ordre public.
            </p>
            <p>
              {" "}
              En cas de manquement de la part d’un Membre à ses obligations
              contractuelles ou légales,{" "}
              <span className="investInThaland">Invest In Thailand</span> se
              réserve le droit de prendre toutes mesures nécessaires pour faire
              cesser les manquements en cause, et ce, sans préjudice de tous
              dommages et intérêts auxquels{" "}
              <span className="investInThaland">Invest In Thailand</span>{" "}
              pourrait prétendre.{" "}
            </p>
            <p>
              En tout état de cause, la responsabilité de{" "}
              <span className="investInThaland">Invest In Thailand</span> sera
              limitée au montant effectivement perçu par elle dans le cadre de
              l’Investissement du Membre sur la Plateforme.
            </p>
            <h4 className="investInThalandColor">
              Article 9 - Propriété intellectuelle
            </h4>
            <p>
              Le Site et la Plateforme, ainsi que l’ensemble des éléments qui
              les composent (textes, images, marques, logos, etc.), sont la
              propriété exclusive de{" "}
              <span className="investInThaland">Invest In Thailand</span>.
            </p>
            <p>
              {" "}
              L’Utilisateur et le Membre s’engagent à ne pas copier, reproduire,
              représenter, diffuser, distribuer, publier, exploiter, modifier ou
              utiliser de quelque manière que ce soit, tout ou partie des
              éléments du Site et/ou de la Plateforme, sans l’autorisation
              expresse et préalable de{" "}
              <span className="investInThaland">Invest In Thailand</span>.
            </p>
            <p>
              {" "}
              Toute exploitation non autorisée du Site, de la Plateforme et/ou
              de ses éléments pourra donner lieu à des poursuites judiciaires.
            </p>
            <h4 className="investInThalandColor">
              Article 10 - Données personnelles
            </h4>
            <p>
              La Plateforme collecte et traite les Données à caractère personnel
              des Membres dans le respect des dispositions de la loi applicable
              et de sa Politique de protection des données.
            </p>
            <p>
              {" "}
              Lors de leur navigation sur le Site et/ou leur inscription sur la
              Plateforme, les Utilisateurs et les Membres acceptent la collecte
              et le traitement de leurs Données à caractère personnel par{" "}
              <span className="investInThaland">Invest In Thailand</span> dans
              les conditions et finalités décrites dans ladite Politique de
              protection des données.
            </p>
            <h4 className="investInThalandColor">Article 11 - Résiliation</h4>
            <p>
              Les Utilisateurs sont libres de cesser d’utiliser le Site à tout
              moment. Ils peuvent également choisir de supprimer leur Compte à
              tout moment.
            </p>
            <p>
              {" "}
              <span className="investInThaland">Invest In Thailand</span> se
              réserve le droit de restreindre, suspendre ou résilier le droit
              d’accès et d’utilisation du Site et/ou de la Plateforme, pour des
              motifs légitimes et notamment en cas de violation des présentes
              CGU et du Code de bonnes conduites.
            </p>
            <p>
              <span className="investInThaland">Invest In Thailand</span> se
              réserve également le droit de restreindre, suspendre ou résilier
              le droit d’accès et d’utilisation du Site et/ou de la Plateforme
              en cas d’abus manifeste de la part de l’Utilisateur ou du Membre
              ou de comportement contraire à l’ordre public.{" "}
            </p>
            <p>
              En cas de résiliation, quelle qu’en soit la cause, les
              Utilisateurs et les Membres ne pourront prétendre à aucune
              indemnisation ou compensation.
            </p>
            <h4 className="investInThalandColor">
              Article 14 - Liens vers des sites tiers
            </h4>
            <p>
              Le Site et/ou la Plateforme peut contenir des liens vers des sites
              tiers, partenaires ou non de{" "}
              <span className="investInThaland">Invest In Thailand</span>. Ces
              liens sont fournis à titre informatif et{" "}
              <span className="investInThaland">Invest In Thailand</span>{" "}
              n’exerce aucun contrôle sur le contenu de ces sites tiers. La
              présence de ces liens ne saurait engager la responsabilité de{" "}
              <span className="investInThaland">Invest In Thailand</span>
              quant au contenu, à la sécurité ou à la disponibilité de ces sites
              tiers.{" "}
            </p>
            <p>
              Les Utilisateurs et les Membres reconnaissent que leur accès et
              leur utilisation de sites tiers sont soumis aux conditions
              générales d'utilisation et politiques de confidentialité propres à
              ces sites. BLOKS FONCIERE 1 décline toute responsabilité quant aux
              éventuels dommages ou pertes causés par l'accès ou l'utilisation
              de ces sites tiers.
            </p>
            <h4 className="investInThalandColor">
              Article 15 - Propriété intellectuelle des contenus publiés par les
              Utilisateurs et les Membres
            </h4>
            <p>
              En publiant des contenus (textes, images, vidéos, etc.) sur le
              Site ou la Plateforme, les Utilisateurs et les Membres
              garantissent qu'ils détiennent tous les droits nécessaires pour
              permettre la publication de ces contenus et que leur diffusion ne
              porte pas atteinte aux droits de tiers.
            </p>
            <p>
              {" "}
              Les Utilisateurs et les Membres concèdent à{" "}
              <span className="investInThaland">Invest In Thailand</span> une
              licence non exclusive, gratuite et mondiale pour utiliser,
              reproduire, représenter, distribuer et communiquer au public les
              contenus publiés sur le Site ou la Plateforme, notamment à des
              fins de promotion de{" "}
              <span className="investInThaland">Invest In Thailand</span>, du
              Site et de la Plateforme. Les contenus publiés par les
              Utilisateurs et les Membres restent sous leur entière
              responsabilité.{" "}
              <span className="investInThaland">Invest In Thailand</span> se
              réserve le droit de supprimer tout contenu publié sur le Site ou
              la Plateforme qui serait contraire aux présentes CGU, au Code de
              bonnes conduites ou qui violerait les droits de{" "}
              <span className="investInThaland">Invest In Thailand</span> ou de
              tiers.
            </p>
            <h4 className="investInThalandColor">
              Article 16 - Modifications des CGU
            </h4>
            <p>
              <span className="investInThaland">Invest In Thailand</span> se
              réserve le droit de modifier les présentes CGU à tout moment. Les
              Utilisateurs et les Membres seront informés de ces modifications
              par tout moyen jugé approprié par{" "}
              <span className="investInThaland">Invest In Thailand</span>
              1, notamment par une notification sur le Site ou la Plateforme.
            </p>
            <p>
              Les modifications des CGU prendront effet à compter de leur
              publication sur le Site ou la Plateforme. Les Utilisateurs et les
              Membres sont invités à consulter régulièrement les CGU afin de
              prendre connaissance des éventuelles mises à jour.{" "}
            </p>
            <p>
              En continuant à utiliser le Site et/ou la Plateforme après la
              publication des modifications des CGU, les Utilisateurs et les
              Membres expriment leur acceptation des nouvelles dispositions.
            </p>
            <h4 className="investInThalandColor">
              Article 17 - Loi applicable et compétence juridictionnelle
            </h4>
            <p>
              Les présentes CGU sont régies par la loi française. Tout litige
              relatif à leur interprétation, leur validité, leur exécution ou
              leur résiliation sera soumis à la compétence exclusive des
              tribunaux de Paris.{" "}
            </p>
            <p>
              En cas de litige entre{" "}
              <span className="investInThaland">Invest In Thailand</span> et un
              Membre, les parties s'efforceront de résoudre le litige à
              l'amiable. À défaut de règlement amiable, le litige sera soumis à
              la compétence exclusive des tribunaux, nonobstant pluralité de
              défendeurs ou appel en garantie, même pour les procédures
              d'urgence ou conservatoires en référé ou par requête.
            </p>
            <h4 className="investInThalandColor">
              Article 18 - Dispositions générales
            </h4>
            <p>
              <b>Communication avec les Utilisateurs</b>
              <br /> Tous les Utilisateurs seront notifiés des éventuelles mises
              à jour apportées aux présentes CGU.
            </p>
            <p>
              Les Utilisateurs non-inscrits à la Plateforme ont la possibilité
              de contacter{" "}
              <span className="investInThaland">Invest In Thailand</span> depuis
              le formulaire de contact rendu disponible sur le Site ou via
              l’adresse contact@edenroc-immobilier.com.
            </p>
            <p>
              {" "}
              Les Membres de la Plateforme seront régulièrement notifiés ou
              informés par{" "}
              <span className="investInThaland">Invest In Thailand</span> des
              nouveautés ou évènements affectant la Plateforme et/ou ses
              Fonctionnalités, de leurs mises à jour éventuelles, etc. Ces
              notifications ou informations pourront notamment prendre la forme
              d’une notification depuis le Compte du Membre et/ou d’une
              communication par email.
            </p>
            <p>
              {" "}
              Le Site et la Plateforme permettent à tout Utilisateur de
              s’inscrire à la newsletter de{" "}
              <span className="investInThaland">Invest In Thailand</span>. Les
              Utilisateurs pourront se désabonner à tout moment en cliquant sur
              le lien de désinscription à la fin de chaque newsletter reçue.{" "}
            </p>
            <p>
              <b>Système de signalement et modération de la Plateforme</b>
              <br /> Les Membres sont informés que{" "}
              <span className="investInThaland">Invest In Thailand</span> n’a
              pas une obligation générale de surveillance des contenus rendus
              accessibles sur la Plateforme.
            </p>
            <p>
              {" "}
              Toutefois, les Membres peuvent signaler des contenus qui
              présenteraient un caractère manifestement illégal, illicite,
              dangereux, discriminatoire, haineux, portant atteinte aux droits
              de tiers, ou tout simplement contraire aux présentes CGU en
              contactant directement{" "}
              <span className="investInThaland">Invest In Thailand</span> à
              l’adresse contact@edenroc-immobilier.com. Tout signalement d’un
              contenu manifestement abusif ou infondé pourra exposer son auteur
              à des poursuites judiciaires.{" "}
            </p>
            <p>
              <b>Indépendance des clauses</b>
              <br /> Si l’une des stipulations des présentes CGU s’avérait être
              nulle au regard d’une quelconque règle de droit en vigueur ou
              d’une décision de justice devenue définitive, elle serait alors
              réputée non écrite, sans pour autant entraîner la nullité des CGU
              ni altérer la validité de ses autres stipulations.
            </p>
            <p>
              {" "}
              Les autres stipulations des CGU demeureront inchangées et
              continueront à s’appliquer comme si la ou les stipulations nulles
              et sans objet ne figuraient plus aux présentes, sauf dans
              l’hypothèse où elles présenteraient un caractère indissociable
              avec les stipulations réputées non écrites.{" "}
            </p>
            <p>
              <b>Renonciation</b>
              <br /> Le fait que l’une ou l’autre des Parties ne revendique pas
              l’application d’une clause quelconque des présentes CGU, ou
              acquiesce à son inexécution, que ce soit de manière permanente ou
              temporaire, ne pourra être interprété comme une renonciation par
              cette Partie au bénéfice de ladite clause.
            </p>
            <p>
              <b> Loi applicable et règlement des litiges</b>
              <br /> Les présentes CGU sont régies par le droit français, sauf
              disposition impérative contraire.{" "}
            </p>
            <p>
              Les Parties s’engagent à rechercher, en toutes circonstances, une
              solution amiable de règlement des différends et litiges pouvant
              survenir entre elles.{" "}
            </p>
            <p>
              En cas de différend survenant entre les Parties, la Partie
              demanderesse adresse à l’autre Partie une notification formelle
              détaillant le litige survenu et les demandes en découlant :{" "}
              <ul>
                <li>
                  Le Membre peut adresser cette notification par email à
                  l’adresse suivante : contact@edenroc-immobilier.com ou par
                  courrier postal à l’adresse du siège social de{" "}
                  <span className="investInThaland">Invest In Thailand</span>.{" "}
                </li>
                <li>
                  {" "}
                  <span className="investInThaland">Invest In Thailand</span>
                  adressera, le cas échéant, cette notification par email ou par
                  courrier postal aux adresses renseignées par le Membre lors de
                  son inscription sur la Plateforme.
                </li>
              </ul>
            </p>
            <p>
              {" "}
              Les Parties mettent en œuvre les efforts nécessaires pour
              résoudre, de bonne foi, le litige survenu entre elles.
            </p>
            <p>
              {" "}
              Dans le cas où le litige n’aurait pas été résolu dans un délai de
              trois (3) mois suivant la réception par l’une des Parties de la
              notification préalable formelle de l’autre Partie, l’une ou
              l’autre des Parties pourra décider d’engager une procédure
              judiciaire devant le tribunal compétent.
            </p>
            <h4 className="investInThalandColor">
              Article 19 - Modifications des CGU
            </h4>
            <p>
              <span className="investInThaland">Invest In Thailand</span> se
              réserve le droit de modifier à tout moment les présentes CGU. Les
              Utilisateurs seront informés des modifications par tout moyen
              approprié, notamment par une notification sur le Site et/ou la
              Plateforme ou par un email. Les Utilisateurs auront la possibilité
              de prendre connaissance des nouvelles CGU avant de continuer à
              utiliser le Site et/ou la Plateforme.
            </p>
            <p>
              {" "}
              En continuant à utiliser le Site et/ou la Plateforme après la date
              d'entrée en vigueur des nouvelles CGU, les Utilisateurs
              reconnaissent et acceptent les modifications apportées aux CGU.
            </p>
            <h4 className="investInThalandColor">
              Article 20 - Non-renonciation
            </h4>
            <p>
              Le fait pour{" "}
              <span className="investInThaland">Invest In Thailand</span> de ne
              pas exercer un droit ou de ne pas exiger l'exécution d'une
              obligation prévue par les présentes CGU ne saurait être interprété
              comme une renonciation à ce droit ou cette obligation. Les droits
              et obligations prévus par les CGU restent en vigueur.
            </p>
            <h4 className="investInThalandColor">
              Article 21 - Intégralité de l'accord
            </h4>
            <p>
              Les présentes CGU constituent l'intégralité de l'accord entre
              <span className="investInThaland">Invest In Thailand</span> et les
              Utilisateurs concernant l'utilisation du Site et/ou de la
              Plateforme. Elles remplacent tous les accords antérieurs entre les
              parties, qu'ils soient écrits ou verbaux.
            </p>
            <h4 className="investInThalandColor">Article 22 - Divisibilité</h4>
            <p>
              Si l'une des dispositions des présentes CGU est déclarée nulle ou
              inapplicable en vertu de la loi, d'une réglementation ou d'une
              décision judiciaire définitive, cette nullité n'affectera pas la
              validité ou l'applicabilité des autres dispositions des CGU, qui
              demeureront en vigueur.
            </p>
            <h4 className="investInThalandColor">Article 23 - Force majeure</h4>
            <p>
              Aucune des parties ne sera tenue responsable de l'inexécution ou
              du retard dans l'exécution de ses obligations au titre des
              présentes CGU si cet inexécution ou ce retard résulte d'un cas de
              force majeure, tel que défini par la loi.
            </p>
            <h4 className="investInThalandColor">
              Article 24 - Droit applicable et juridiction compétente
            </h4>
            <p>
              Les présentes CGU sont régies par le droit français. Tout litige
              relatif à l'interprétation, la validité, l'exécution ou la
              résiliation des présentes CGU sera soumis à la compétence
              exclusive des tribunaux français compétents.
            </p>
          </div>
        </div>
      </div>
      <div className="container mt-5">
        <div className="row justify-content-center align-items-start"></div>
        {/* Second Column - Image (wrapped in Card) */}
        <div className="col-md-6 row justify-content-center align-items-start"></div>
      </div>
    </div>
  );
};
