import React from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import { IoIosRefresh } from "react-icons/io";
import { FaHome } from "react-icons/fa";

const Offers = () => {
  return (
    <Container>
      <Row className="justify-content-center">
        {/* Première colonne */}
        <Col md={6} className="mb-4">
          <Card style={{ borderRadius: "20px" }}>
            <Card.Header style={{ backgroundColor: "#38AAAE", color: "white" }}>
              <IoIosRefresh size={22} /> &nbsp; Achat / Revente
            </Card.Header>
            <Card.Body>
              <Card.Title>Durée 12 mois</Card.Title>
              <Card.Text>
                <ul style={{ listStyle: "none" }}>
                  <li>Revenu stable</li>
                  <li>Faible variabilité</li>
                  <li>Disponibilité immédiate</li>
                  <li>Croissance de l'investissement</li>
                  <li>Protection contre l'inflation</li>
                </ul>
              </Card.Text>
            </Card.Body>
            <h6>Profit anticipé annuel > 12%</h6>
          </Card>
        </Col>

        {/* Deuxième colonne */}
        <Col md={6} className="mb-4">
          <Card style={{ borderRadius: "20px" }}>
            <Card.Header style={{ backgroundColor: "#38AAAE", color: "white" }}>
              <FaHome size={22} /> &nbsp;Achat locatif
            </Card.Header>
            <Card.Body>
              <Card.Title>Achat avec bail à long terme</Card.Title>
              <Card.Text>
                <ul style={{ listStyle: "none" }}>
                  <li>Revenu stable</li>
                  <li>Faible variabilité</li>
                  <li>Disponibilité immédiate</li>
                  <li>Protection contre l'inflation</li>
                  <li>&nbsp;</li>
                </ul>
              </Card.Text>
            </Card.Body>
            <h6>Profit anticipé annuel > 8%</h6>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default Offers;
