import React, { useState, useRef, useEffect } from "react";
import Button from "react-bootstrap/Button";
import ApiService from "../Services/ApiService";
import { useUser } from "../Contexts/UserContext";

function DocumentUpload() {
  const videoRef = useRef<HTMLVideoElement | null>(null);
  const [mode, setMode] = useState<"camera" | "file">("camera");
  const [stream, setStream] = useState<MediaStream | null>(null);
  const [photoData, setPhotoData] = useState<string | null>(null);
  const { user, setUser, isUserComplete } = useUser();
  const [images, setImages] = useState<any[]>([]);

  const authToken = user.token;

  useEffect(() => {
    const apiService = new ApiService<any>(
      `${process.env.REACT_APP_CF_API_URL}/Files/documents/${user.id}`,
      authToken
    );

    const fetchData = async () => {
      try {
        const response = await apiService.get();
        if (response.ok) {
          const data = await response.json();
          setImages(data);
        } else {
          console.error("Request failed:", response.status);
        }
      } catch (error: any) {
        console.error("An error occurred:", error.message);
      }
    };

    fetchData();
  }, []);

  const startCamera = async () => {
    try {
      const userMediaStream = await navigator.mediaDevices.getUserMedia({
        video: true,
      });
      setStream(userMediaStream);
      if (videoRef.current) {
        videoRef.current.srcObject = userMediaStream;
      }
    } catch (error) {
      console.error("Error accessing the camera:", error);
    }
  };

  const takePhoto = () => {
    if (videoRef.current) {
      const canvas = document.createElement("canvas");
      canvas.width = videoRef.current.videoWidth;
      canvas.height = videoRef.current.videoHeight;

      const context = canvas.getContext("2d");
      if (context) {
        context.drawImage(videoRef.current, 0, 0, canvas.width, canvas.height);
        const photoDataURL = canvas.toDataURL("image/png");
        setPhotoData(photoDataURL);
        stopCamera();
      }
    }
  };

  const stopCamera = () => {
    if (stream) {
      stream.getTracks().forEach((track) => track.stop());
      setStream(null);
    }
  };

  const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      // Vérifier si le fichier est une image (PNG ou JPG)
      if (file.type === "image/jpeg" || file.type === "image/png") {
        const reader = new FileReader();
        reader.onload = () => {
          setPhotoData(reader.result as string);
        };
        reader.readAsDataURL(file);
      } else {
        // Afficher un message d'erreur si le fichier n'est pas une image
        alert("Veuillez sélectionner un fichier image (PNG ou JPG).");
      }
    }
    // Réinitialiser l'image capturée précédente
    setPhotoData(null);
  };

  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    const file = event.dataTransfer.files[0];
    if (file) {
      // Vérifier si le fichier est une image (PNG ou JPG)
      if (file.type === "image/jpeg" || file.type === "image/png") {
        const reader = new FileReader();
        reader.onload = () => {
          setPhotoData(reader.result as string);
        };
        reader.readAsDataURL(file);
      } else {
        // Afficher un message d'erreur si le fichier n'est pas une image
        alert("Veuillez déposer un fichier image (PNG ou JPG).");
      }
    }
    // Réinitialiser l'image capturée précédente
    setPhotoData(null);
  };

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
  };

  const handleSubmit = async () => {
    const authToken = user.token;

    const apiService = new ApiService<any>(
      `${process.env.REACT_APP_CF_API_URL}/Files/`,
      authToken
    );

    if (photoData) {
      var response;
      response = await apiService.upload64("upload64", photoData);

      try {
        if (response != null && response.ok) {
          alert("La photo a été envoyée avec succès !");
        } else {
          alert("Une erreur s'est produite lors de l'envoi de la photo.");
        }
      } catch (error) {
        console.error("Erreur lors de l'envoi de la photo :", error);
      }
    }
  };

  return (
    <div>
      {mode === "camera" && (
        <div>
          <video ref={videoRef} autoPlay playsInline />
          {!stream ? (
            <Button
              variant="outline-info"
              onClick={startCamera}
              style={{ margin: "10px" }}
            >
              Démarrer la caméra
            </Button>
          ) : null}
          {stream ? (
            <Button
              variant="outline-danger"
              onClick={takePhoto}
              style={{ margin: "10px" }}
            >
              Prendre la photo
            </Button>
          ) : null}
          <Button
            variant="outline-info"
            onClick={() => setMode("file")}
            style={{ margin: "10px" }}
          >
            Choisir un fichier
          </Button>
        </div>
      )}
      {mode === "file" && (
        <div>
          <input
            type="file"
            accept="image/*"
            onChange={handleFileUpload}
            className="custom-file-input"
            id="file-input"
            style={{ display: "none" }}
          />
          <label htmlFor="file-input" className="custom-file-input-button">
            Choisir un fichier
          </label>
          <div
            onDrop={handleDrop}
            onDragOver={handleDragOver}
            style={{
              border: "2px dashed gray",
              borderRadius: "4px",
              padding: "20px",
              margin: "20px 0",
              textAlign: "center",
            }}
          >
            Déposez le fichier ici
          </div>
          <Button
            variant="outline-info"
            onClick={() => setMode("camera")}
            style={{ margin: "10px" }}
          >
            Retour
          </Button>
        </div>
      )}
      {photoData ? <img src={photoData} alt="Captured" /> : null}
      <div>
        <Button variant="primary" onClick={handleSubmit}>
          Envoyer la photo
        </Button>
      </div>
      <h2>Documents</h2>
      <div className="image-gallery">
        {images.map((image) => (
          <div key={image.documentId} className="image-item">
            <img
              src={`${process.env.REACT_APP_CF_API_URL}/files/documents/document/${image.fileName}`}
              alt={image.Description}
            />
          </div>
        ))}
      </div>
      <br />
    </div>
  );
}

export default DocumentUpload;
