import React from "react";

const Image = ({ imagePath }) => {
  return (
    <img
      src={imagePath}
      alt="Placeholder"
      style={{
        borderRadius: "50px",
        maxWidth: "100%",
        width: "100%",
        marginTop: "50px",
      }}
    />
  );
};

export default Image;
