import React from "react";
import endenroc from "../Images/Edenroc.jpg";
import endenrocArchi from "../Images/edenrocArchi.jpg";

const Partners = () => {
  return (
    <div className="container mt-5">
      <div className="row justify-content-center text-center flex-wrap">
        <div className="col-md-3 custom-top-margin">
          <p>
            <div style={{ maxWidth: "100%", width: "100px", margin: "0 auto" }}>
              <img src={endenroc} alt="Endenroc" style={{ maxWidth: "100%" }} />
            </div>
          </p>
        </div>
        <div className="col-md-3 custom-top-margin">
          <p>
            <div style={{ maxWidth: "100%", width: "100px", margin: "0 auto" }}>
              <img
                src={endenrocArchi}
                alt="Endenroc Architecture"
                style={{ maxWidth: "100%" }}
              />
            </div>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Partners;
