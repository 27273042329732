import React from "react";
import { Nav } from "react-bootstrap";
import { BsCardHeading, BsFolderSymlink } from "react-icons/bs";
import { GiPapers } from "react-icons/gi";
import { CiWallet } from "react-icons/ci";

const AccountNavigation = () => {
  return (
    <div className="account-menu">
      <Nav className="flex-column">
        <Nav.Link href="#/account" className="nav-link">
          <BsCardHeading />
          Mon compte
        </Nav.Link>

        <Nav.Link href="#/subscription" className="nav-link">
          <BsFolderSymlink />
          Mes souscriptions
        </Nav.Link>
      </Nav>
    </div>
  );
};

export default AccountNavigation;
