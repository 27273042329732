import React from "react";
import legalNotice from "../Images/cookies.jpg";
import Image from "../Components/Image";

export const Cookies = () => {
  return (
    <div>
      <div className="container mt-5">
        <div className="row justify-content-center align-items-start">
          {" "}
          {/* First Column - Text */}
          <div className="col-md-6 custom-top-margin">
            <h2 style={{ margin: 30 }}>Politique d'utilisation des cookies</h2>
            <p>
              Cette Politique d'utilisation des cookies (ci-après dénommée la
              "Politique") explique comment{" "}
              <span className="investInThaland">Invest In Thailand</span>{" "}
              utilise les cookies et autres technologies de suivi similaires sur
              notre site web, nos applications mobiles et nos autres services en
              ligne (collectivement désignés comme les "Services").
            </p>
            <p>
              {" "}
              Nous attachons une grande importance à la protection de la vie
              privée de nos utilisateurs et souhaitons être transparents
              concernant nos pratiques en matière de cookies.
            </p>
            <h4 className="investInThalandColor">Qu'est-ce qu'un cookie ?</h4>
            <p>
              Un cookie est un petit fichier texte contenant un identifiant
              unique qui est placé sur votre ordinateur ou votre appareil mobile
              lorsque vous visitez un site web ou utilisez une application.
            </p>
            <p>
              Il permet au site web ou à l'application de se souvenir de vos
              actions et préférences (telles que la connexion, la langue, la
              taille de la police et d'autres préférences d'affichage) sur une
              période donnée.{" "}
            </p>
            <p>
              Les cookies sont largement utilisés pour rendre les sites web plus
              efficaces, améliorer l'expérience utilisateur et fournir des
              informations aux propriétaires du site.
            </p>
          </div>
          {/* Second Column - Image (wrapped in Card) */}
          <div className="col-md-6 row justify-content-center align-items-start">
            <div style={{ width: "100%", maxWidth: "500px" }}>
              <Image imagePath={legalNotice} />
            </div>
          </div>
        </div>
        <div className="row justify-content-center align-items-start">
          {" "}
          {/* First Column - Text */}
          <div className="col-md-12 custom-top-margin"></div>
          <h4 className="investInThalandColor">
            Comment utilisons-nous les cookies ?
          </h4>
          <p>
            Nous utilisons des cookies à diverses fins, notamment pour :
            <ul>
              <li>
                Assurer le bon fonctionnement de nos Services : Certains cookies
                sont essentiels au bon fonctionnement de nos Services. Ils nous
                permettent de vous identifier lorsque vous vous connectez à
                votre compte, de garder en mémoire vos préférences linguistiques
                et de vous offrir une expérience de navigation fluide.
              </li>
              <li>
                Améliorer l'expérience utilisateur : Nous utilisons des cookies
                pour comprendre comment vous interagissez avec nos Services, ce
                qui nous aide à améliorer leur convivialité. Ces cookies nous
                permettent de suivre les pages que vous visitez, les liens sur
                lesquels vous cliquez et d'autres actions que vous effectuez
                pendant votre utilisation de nos Services.
              </li>
              <li>
                Personnaliser votre expérience : Les cookies nous aident à vous
                proposer des contenus et des publicités personnalisés en
                fonction de vos intérêts et de vos préférences. Ils nous
                permettent également de mémoriser vos choix concernant les
                cookies et autres paramètres du site.
              </li>
              <li>
                Analyser et améliorer nos Services : Nous utilisons des cookies
                pour recueillir des données analytiques sur l'utilisation de nos
                Services. Ces informations nous aident à comprendre comment nos
                utilisateurs interagissent avec nos Services, à détecter et
                résoudre d'éventuels problèmes techniques, et à améliorer
                continuellement nos offres.
              </li>
              <li>
                Fournir des publicités ciblées : Nous collaborons avec des
                partenaires publicitaires pour diffuser des publicités sur nos
                Services. Les cookies sont utilisés pour sélectionner et
                diffuser des annonces pertinentes en fonction de vos intérêts et
                de votre comportement de navigation.
              </li>
            </ul>
          </p>
          <h4 className="investInThalandColor">
            Quels types de cookies utilisons-nous ?
          </h4>
          <p>
            Cookies de session : Ces cookies sont temporaires et sont stockés
            sur votre appareil uniquement pendant votre session de navigation.
            Ils sont automatiquement supprimés de votre appareil lorsque vous
            fermez votre navigateur.
          </p>
          <p>
            <ul>
              <li>
                Cookies de session : Ces cookies sont temporaires et sont
                stockés sur votre appareil uniquement pendant votre session de
                navigation. Ils sont automatiquement supprimés de votre appareil
                lorsque vous fermez votre navigateur.
              </li>
              <li>
                Cookies persistants : Ces cookies restent sur votre appareil
                pendant une période prédéfinie, même après la fermeture de votre
                navigateur. Ils sont activés chaque fois que vous visitez nos
                Services.
              </li>
              <li>
                Cookies propriétaires : Nous utilisons des cookies propriétaires
                qui sont définis et contrôlés directement par nous pour les
                besoins spécifiques de nos Services.
              </li>
              <li>
                Cookies tiers : Certains cookies sont définis et gérés par des
                partenaires tiers qui collaborent avec nous pour fournir des
                services publicitaires, analytiques et autres sur nos Services.
              </li>
            </ul>
          </p>
          <h4 className="investInThalandColor">
            Comment contrôler les cookies ?
          </h4>
          <p>
            Vous avez le droit de contrôler l'utilisation des cookies et pouvez
            ajuster vos préférences de cookies à tout moment. Vous pouvez
            généralement configurer votre navigateur pour refuser tous les
            cookies ou être averti lorsqu'un cookie est envoyé. Veuillez noter
            que le refus de certains cookies peut affecter le fonctionnement et
            la convivialité de nos Services.
          </p>
          <p>
            Pour en savoir plus sur la gestion des cookies dans votre
            navigateur, veuillez consulter les paramètres d'aide de votre
            navigateur ou visiter les sites web d'assistance des principaux
            navigateurs.
          </p>
          <h4 className="investInThalandColor">
            Modifications de la Politique
          </h4>
          <p>
            Nous nous réservons le droit de mettre à jour ou de modifier cette
            Politique à tout moment. Toute modification apportée à cette
            Politique entrera en vigueur dès sa publication sur cette page. Nous
            vous encourageons à consulter régulièrement cette Politique pour
            rester informé des éventuelles modifications.
          </p>
          <h4 className="investInThalandColor">Contact</h4>
          <p>
            Si vous avez des questions, des préoccupations ou des demandes
            concernant cette Politique d'utilisation des cookies, veuillez nous
            contacter à l'adresse suivante :<br />{" "}
            <a href="mailto:contact@edenroc-immobilier.com?subject=[Invest in Thaland] - Informations&body=Bonjour">
              contact@edenroc-immobilier.com
            </a>
            .
          </p>
          <p>
            En utilisant nos Services, vous acceptez l'utilisation des cookies
            conformément à cette Politique.
          </p>
        </div>
      </div>
      <div className="container mt-5">
        <div className="row justify-content-center align-items-start"></div>
        {/* Second Column - Image (wrapped in Card) */}
        <div className="col-md-6 row justify-content-center align-items-start"></div>
      </div>
    </div>
  );
};
