import React from "react";
import { BsArrowRightCircle } from "react-icons/bs";

const generateListWithIcons = ({ data, property, iconMappings }) => {
  return (
    <ul style={{ listStyleType: "none", paddingInlineStart: "0" }}>
      {data?.[property]?.split("\\r\\n").map((item, index) => {
        const iconKey = Object.keys(iconMappings).find((key) =>
          item.includes(key)
        );

        const IconComponent = iconMappings[iconKey] || null;

        return (
          <li
            key={index}
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            {IconComponent && <IconComponent style={{ marginRight: "10px" }} />}
            {item !== "" ? item.replace(new RegExp(iconKey, "g"), "") : <br />}
          </li>
        );
      })}
    </ul>
  );
};

export default generateListWithIcons;
