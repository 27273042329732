import React, { useState, useEffect } from "react";
import signup from "../Images/signup.jpg";
import Image from "../Components/Image";
import { Button } from "react-bootstrap";
import { MdAlternateEmail } from "react-icons/md";
import { RiLockPasswordLine } from "react-icons/ri";
import ApiService from "../Services/ApiService";
import { useUser } from "../Contexts/UserContext";
import { useNavigate } from "react-router-dom";

export const Signin = () => {
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertStyle, setAlertStyle] = useState("");
  const { setUser } = useUser();
  const navigate = useNavigate();
  const { user } = useUser();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errors, setErrors] = useState({
    email: "",
    password: "",
  });

  // Utilisez useEffect pour vérifier si l'utilisateur est connecté et le rediriger
  useEffect(() => {
    if (user) {
      navigate("/account");
    }
  }, [user, navigate]);

  const handleSignIn = async () => {
    const formData = {
      email,
      password: password,
    };

    const apiService = new ApiService<any>(
      process.env.REACT_APP_CF_API_URL + "/token"
    );

    try {
      const response = await apiService.post("signin", formData);
      const responseData = await response.json();

      if (response.ok) {
        //get and set token
        const authToken = response.headers.get("X-CF-TOKEN");
        responseData.result["token"] = authToken;
        if (
          !responseData.result.phoneIndicatif ||
          responseData.result.phoneIndicatif === ""
        ) {
          responseData.result.phoneIndicatif = "+33";
        }

        setUser(responseData.result);

        navigate("/account");
      } else {
        let message = "Email ou mot de passe incorrect.";
        if (responseData.errorId === -2) {
          message =
            "Email non confirmé. Cliquer sur le lien de l'email envoyé. Regarder vos SPAMS";
        }

        setAlertMessage(message);
        setAlertStyle("danger");
        setShowAlert(true);

        setTimeout(() => {
          setShowAlert(false);
        }, 6000);
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };

  return (
    <div>
      <div className="container mt-5">
        <div className="row justify-content-center align-items-start">
          {/* First Column - Text */}
          <div className="col-md-6 custom-top-margin">
            <h2 style={{ margin: 30 }}>Se connecter</h2>

            <form className="account-form">
              {/* Email */}
              <div className="form-group custom-form-group">
                <label htmlFor="email">Email:</label>
                <div className="input-group mb-3">
                  <div className="input-group-prepend">
                    <span className="input-group-text" id="basic-addon1">
                      <MdAlternateEmail style={{ fontSize: "25px" }} />
                    </span>
                  </div>

                  <input
                    type="text"
                    id="email"
                    className="form-control"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
              </div>

              {/* Password */}
              <div className="form-group custom-form-group">
                <label htmlFor="password">Mot de passe:</label>
                <div className="input-group mb-3">
                  <div className="input-group-prepend">
                    <span className="input-group-text" id="basic-addon1">
                      <RiLockPasswordLine style={{ fontSize: "25px" }} />
                    </span>
                  </div>
                  <input
                    type="password"
                    id="password"
                    className={`form-control ${
                      errors.password ? "is-invalid" : ""
                    }`}
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </div>
              </div>
              <br />
              <br />
              {showAlert && (
                <div
                  className={`alert alert-${alertStyle} text-center`}
                  role="alert"
                >
                  {alertMessage}
                </div>
              )}
              <div className="form-group align-self-end">
                <Button variant="primary" onClick={handleSignIn}>
                  Connexion
                </Button>
              </div>
            </form>
          </div>
          <div className="col-md-6 row justify-content-center align-items-start">
            <Image imagePath={signup} />
          </div>
        </div>
      </div>
      <div className="container mt-5">
        <div className="row justify-content-center align-items-start"></div>
        <div className="col-md-6 row justify-content-center align-items-start"></div>
      </div>
    </div>
  );
};
