import { Home } from "./Pages/home";
import { About } from "./Pages/about";
import { Thailand } from "./Pages/thailand";
import { Hold } from "./Pages/hold";
import { Flip } from "./Pages/flip";
import { FAQ } from "./Pages/faq";
import { Cookies } from "./Pages/cookies";
import { DataUsePolicy } from "./Pages/dataUsePolicy";
import { LegalNotice } from "./Pages/LegalNotice";
import { Cgu } from "./Pages/cgu";
import { Risk } from "./Pages/risk";
import { Testimony } from "./Pages/testimony";
import { Signup } from "./Pages/signup";
import { Signin } from "./Pages/signin";
import { Account } from "./Pages/account";
import { Project } from "./Pages/project";
import { ProjectDetails } from "./Pages/projectDetails";
import { ValidationEmail } from "./Pages/validationEmail";
import { Subscription } from "./Pages/subscription";
import { Proof } from "./Pages/proof";
import { Wallet } from "./Pages/wallet";
import "./App.css";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import { UserProvider, useUser } from "./Contexts/UserContext";

import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";

import Navigation from "./Components/Navigation";
import Footer from "./Components/Footer";
import React, { useEffect } from "react";

function App() {
  //Footer links - to go to the top page
  const location = useLocation();

  useEffect(() => {
    if (location.search.includes("scroll=top")) {
      window.scrollTo(0, 0);
    }
  }, [location.search]);

  return (
    <UserProvider>
      <div>
        <header>
          <Navigation />
        </header>

        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/home" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/thailand" element={<Thailand />} />
          <Route path="/hold" element={<Hold />} />
          <Route path="/flip" element={<Flip />} />
          <Route path="/faq" element={<FAQ />} />
          <Route path="/dataUsePolicy" element={<DataUsePolicy />} />
          <Route path="/legalNotice" element={<LegalNotice />} />
          <Route path="/cookies" element={<Cookies />} />
          <Route path="/cgu" element={<Cgu />} />
          <Route path="/risk" element={<Risk />} />
          <Route path="/testimony" element={<Testimony />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/signin" element={<Signin />} />
          <Route path="/project" element={<Project />} />
          <Route path="/validationEmail" element={<ValidationEmail />} />
          <Route
            path="/projectDetails/:projectId"
            element={<ProjectDetails />}
          />

          <Route path="/account" element={<ProtectedAccount />} />
          <Route path="/subscription" element={<ProtectedSubscription />} />
          <Route path="/proof" element={<ProtectedProof />} />
          <Route path="/wallet" element={<ProtectedWallet />} />
        </Routes>

        <Footer />
      </div>
    </UserProvider>
  );
}

function ProtectedAccount() {
  const { user } = useUser();

  // Si l'utilisateur est connecté, renvoyer le composant Home, sinon rediriger vers la page de connexion
  return user ? <Account /> : <Navigate to="/signin" />;
}

function ProtectedSubscription() {
  const { user } = useUser();

  // Si l'utilisateur est connecté, renvoyer le composant Home, sinon rediriger vers la page de connexion
  return user ? <Subscription /> : <Navigate to="/signin" />;
}

function ProtectedProof() {
  const { user } = useUser();

  // Si l'utilisateur est connecté, renvoyer le composant Home, sinon rediriger vers la page de connexion
  return user ? <Proof /> : <Navigate to="/signin" />;
}

function ProtectedWallet() {
  const { user } = useUser();

  // Si l'utilisateur est connecté, renvoyer le composant Home, sinon rediriger vers la page de connexion
  return user ? <Wallet /> : <Navigate to="/signin" />;
}

export default App;
