import React from "react";
import Accordion from "react-bootstrap/Accordion";
import faq from "../Images/faq.jpg";
import Image from "../Components/Image";

export const FAQ = () => {
  return (
    <div>
      <div>
        <div className="container mt-5">
          <div className="row justify-content-center align-items-start">
            {" "}
            {/* First Column - Text */}
            <div className="col-md-6 custom-top-margin">
              {" "}
              <h2 style={{ margin: 30 }}>FAQ</h2>{" "}
              <Accordion>
                <Accordion.Item eventKey="0">
                  <Accordion.Header>
                    Quel type de contrat lie l'investisseur à la société ?
                  </Accordion.Header>
                  <Accordion.Body>
                    <p>
                      S'il s'agit d'une opération d'achat revente, il s'agit
                      d'un contrat de prêt avec intérêts.
                    </p>
                    <p>
                      S'il s'agit d'une opération d'investissement locatif, vous
                      détenez des parts sociales de la société qui détient
                      indirectement le bien immobilier.
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="3">
                  <Accordion.Header>
                    Qu'est-ce qu'une Part d'un bien ?
                  </Accordion.Header>
                  <Accordion.Body>
                    <p> Il s'agit d'investissement participatif fractionné.</p>

                    <p>
                      L'investissement fractionné consiste à diviser
                      l’acquisition d’un actif immobilier en part sociale de
                      valeur nominale de 15 000 €, ouvrant un droit
                      proportionnel aux revenus et à la plus-value de cession.
                    </p>
                    <p>
                      Ce mécanisme permet de financer l’acquisition d’un actif à
                      plusieurs souscripteurs plutôt que par un seul et même
                      investisseur. Le bien est géré par nos soins et nous nous
                      occupons de toutes les formalités et les aspects pratiques
                      liés à la propriété.
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="4">
                  <Accordion.Header>
                    Quelles sont les garanties ?
                  </Accordion.Header>
                  <Accordion.Body>
                    <p>
                      Vous détenez les parts d’une société. Vous êtes
                      indirectement propriétaire du bien immobilier.
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="6">
                  <Accordion.Header>
                    Puis-je revendre mes parts de société ?
                  </Accordion.Header>
                  <Accordion.Body>
                    <p>Oui</p>

                    <p>
                      Les parts de société sont négociables et peuvent être
                      vendues ou transférées par leur propriétaire.
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="7">
                  <Accordion.Header>Suis-je propriétaire ?</Accordion.Header>
                  <Accordion.Body>
                    <p>
                      Pour chaque acquisition d'un bien immobilier, notre
                      société procède à une émission de part sociale simples
                      (titres financiers) d'une valeur nominale d'un euro.
                      Ainsi, vous ne devenez pas juridiquement propriétaire de
                      l'actif immobilier mais d’un droit financier proportionnel
                      à votre investissement.{" "}
                    </p>
                    <p>
                      Ce droit porte sur les loyers et la plus-value éventuelle
                      lors de la revente. Les revenus présentés sur la
                      plateforme ne sont pas garantis. L’ensemble des droits des
                      investisseurs attachés à la part sociale est détaillée
                      dans le contrat, disponible au moment de votre
                      souscription.
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="8">
                  <Accordion.Header>Quelle est la fiscalité ?</Accordion.Header>
                  <Accordion.Body>
                    <p>
                      S'il s'agit d'une opération d'achat revente, vous serez
                      imposé à hauteur de 15% des gains en Thaîlande. <br />
                      Grâce au traité de non double imposition, vous ne serez
                      pas re taxé en France.
                    </p>
                    <p>
                      S'il s'agit d'une opération d'investissement locatif, vous
                      détenez des parts sociales de la société à Singapour qui
                      détient indirectement le bien immobilier.
                      <br />
                      Il y a 0% d'impôt sur les dividendes versés chaque mois.
                      <br />
                      En France, vous devrez déclarer vos gains et être soumis
                      au barème Français.
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
              <br />
              <br />
            </div>
            {/* Second Column - Image (wrapped in Card) */}
            <div className="col-md-6 row justify-content-center align-items-start">
              <div style={{ width: "100%", maxWidth: "500px" }}>
                <Image imagePath={faq} />
              </div>
            </div>
          </div>
        </div>
        <div className="container mt-5">
          <div className="row justify-content-center align-items-start">
            {" "}
            {/* First Column - Text Empty line */}
          </div>
        </div>
      </div>
    </div>
  );
};
