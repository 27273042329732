import React, { useState, useEffect } from "react";
import AccountMenu from "../Components/AccountNavigation";
import { useUser } from "../Contexts/UserContext";
import ApiService from "../Services/ApiService";
import DocumentUpload from "../Components/DocumentUpload";

export const Proof = () => {
  const { user, setUser } = useUser();
  /*
  useEffect(() => {
    const fetchData = async () => {

      const apiService = new ApiService<any[]>(
        process.env.REACT_APP_CF_API_URL + "/project/projects/" + user?.id,
        user.token
      );

      try {
        const response = await apiService.get();
        if (response.ok) {
          const data = await response.json();
          setProjects(data);
        } else {
          console.error("Request failed:", response.status);
        }
      } catch (error: any) {
        console.error("An error occurred:", error.message);
      }
    };

    fetchData();
  }, []);*/

  return (
    <div className="container">
      <div className="row">
        <div className="col-md-2">
          <h1>&nbsp;</h1>
          <AccountMenu />
        </div>
        <div className="col-md-10">
          <h1>Mes documents</h1>
          {<DocumentUpload />}
        </div>
      </div>
    </div>
  );
};
