import React from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import { MdConstruction } from "react-icons/md";
import { TbPigMoney } from "react-icons/tb";
import { GiBuyCard } from "react-icons/gi";
import { FaTruckMoving } from "react-icons/fa";

const HoldProcess = () => {
  return (
    <div>
      <Container>
        <Row className="justify-content-center">
          {/* Première colonne */}
          <Col xs={6} sm={6} md={3} className="mb-4">
            <Card style={{ borderRadius: "20px" }}>
              <Card.Header
                style={{ backgroundColor: "#38AAAE", color: "white" }}
              >
                <GiBuyCard size={22} /> &nbsp; Achat du bien
              </Card.Header>
              <Card.Body>
                <Card.Text>
                  Avec toute notre expertise et notre vaste réseau, l'équipe d'
                  <span className="investInThaland">Invest In Thailand</span> se
                  donne pour mission de dénicher des biens immobiliers
                  d'exception, situés dans des emplacements de premier choix.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>

          {/* Deuxième colonne */}
          <Col xs={6} sm={6} md={3} className="mb-4">
            <Card style={{ borderRadius: "20px" }}>
              <Card.Header
                style={{ backgroundColor: "#38AAAE", color: "white" }}
              >
                <MdConstruction size={22} /> &nbsp; Travaux et décoration
              </Card.Header>
              <Card.Body>
                <Card.Text>
                  Notre équipe{" "}
                  <span className="investInThaland">Invest in Thailand</span>{" "}
                  s'occupe des travaux et de la décoration avec un souci du
                  détail et du goût. Vous pouvez compter sur nous pour créer un
                  intérieur exceptionnel
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>

          {/* Troisième colonne */}
          <Col xs={6} sm={6} md={3} className="mb-4">
            <Card style={{ borderRadius: "20px" }}>
              <Card.Header
                style={{ backgroundColor: "#38AAAE", color: "white" }}
              >
                <FaTruckMoving size={22} /> &nbsp; Mise en location
              </Card.Header>
              <Card.Body>
                <Card.Text>
                  Après l'achat du bien, notre équipe trouve le locataire idéal
                  et facilite son emménagement. Vous profitez d'une gestion
                  locative sans tracas et d'une expérience positive pour vous et
                  vos locataires.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>

          {/* Quatrième colonne */}
          <Col xs={6} sm={6} md={3} className="mb-4">
            <Card style={{ borderRadius: "20px" }}>
              <Card.Header
                style={{ backgroundColor: "#38AAAE", color: "white" }}
              >
                <TbPigMoney size={22} /> &nbsp; Profit
              </Card.Header>
              <Card.Body>
                <Card.Text>
                  Votre loyer est versé mensuellement sur votre compte{" "}
                  <span className="investInThaland">Invest In Thailand</span>,
                  sans souci ni délai. Profitez de revenus réguliers grâce à
                  votre investissement immobilier en toute simplicité.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default HoldProcess;
