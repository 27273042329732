class ApiService<T> {
  private baseUrl: string;
  private authToken: string | null;

  constructor(baseUrl: string, authToken: string | null = null) {
    this.baseUrl = baseUrl;
    this.authToken = authToken;
  }

  async post(endpoint: string, data: T): Promise<Response> {
    try {
      const headers: Record<string, string> = {
        "Content-Type": "application/json",
      };

      // Include the bearer token if available
      if (this.authToken) {
        headers["Authorization"] = `Bearer ${this.authToken}`;
      }

      const response = await fetch(`${this.baseUrl}/${endpoint}`, {
        method: "POST",
        headers: headers,
        body: JSON.stringify(data),
      });

      return response;
    } catch (error) {
      throw new Error("An error occurred while making the API request.");
    }
  }

  async upload64(endpoint: string, data: string): Promise<Response> {
    try {
      const headers: Record<string, string> = {
        "Content-Type": "application/json",
      };

      let data64 = JSON.stringify(data);

      // Include the bearer token if available
      if (this.authToken) {
        headers["Authorization"] = `Bearer ${this.authToken}`;
      }

      const response = await fetch(`${this.baseUrl}${endpoint}`, {
        method: "POST",
        headers: headers,
        body: data64,
      });

      return response;
    } catch (error) {
      throw new Error("An error occurred while making the API request.");
    }
  }

  async upload(endpoint: string, data: FormData): Promise<Response> {
    try {
      const headers: Record<string, string> = {
        //"Content-Type": "multipart/form-data",
      };

      // Include the bearer token if available
      if (this.authToken) {
        headers["Authorization"] = `Bearer ${this.authToken}`;
      }

      const response = await fetch(`${this.baseUrl}${endpoint}`, {
        method: "POST",
        headers: headers,
        body: data,
      });

      return response;
    } catch (error) {
      throw new Error("An error occurred while making the API request.");
    }
  }

  async get(): Promise<Response> {
    try {
      const headers: Record<string, string> = {
        "Content-Type": "application/json",
      };

      // Include the bearer token if available
      if (this.authToken) {
        headers["Authorization"] = `Bearer ${this.authToken}`;
      }

      const response = await fetch(`${this.baseUrl}`, {
        method: "GET",
        headers: headers,
      });

      return response;
    } catch (error) {
      throw new Error("An error occurred while making the API request.");
    }
  }
}

export default ApiService;
